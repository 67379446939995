angular
    .module('annexaApp')
    .component('annexaTypeaheadFieldset',{
        templateUrl: './components/common/annexa-typeahead-fieldset/annexa-typeahead-fieldset.html',
        controller: ['RestService', 'Language', '$rootScope', function (RestService, Language, $rootScope) {
            var vm = this;

            vm.optionAdd = '';
            vm.contents = [];

            vm.showFieldSet = function() {
                if(vm.onlyOne) {
                    if(angular.element('#' + vm.idForm).scope()) {
                        if (!angular.element('#' + vm.idForm).scope().modalObj.annexaFormly.model.modal_body[vm.propertyShowItem]) {
                            return false;
                        }
                    }
                }

                return true;
            }

            vm.showNew = function () {
                if(vm.onlyOne) {
                    if(vm.contents.length > 0) {
                        var selectedItem = $linq(vm.showItemObjects).singleOrDefault(undefined, "x => x.id == " + angular.element('#'  + vm.idForm).scope().modalObj.annexaFormly.model.modal_body[vm.propertyShowItem]);

                        if(!selectedItem) {
                            return false;
                        }

                        switch (vm.type) {
                            case 'profiles':
                                if(selectedItem.canStartDossier && vm.contents.length > 0) {
                                    return false;
                                }
                        }
                    }
                }

                return true;
            }

            vm.getTitle = function (item) {
                var title = '';

                if(item && item.object) {
                    switch (vm.type) {
                        case 'profiles':
                            if(item.object.userProfiles) {
                                _.forEach(item.object.userProfiles, function (value) {
                                    title += value.user.name + ' ' + value.user.surename1 + (value.user.surename2 ? ' ' + value.user.surename2 + '\n' : '\n');
                                })
                            }
                            break;
                    }
                }

                return title;
            }

            vm.remove = function (index) {
                vm.contents.splice(index, 1);

                $rootScope.$broadcast('annexaTypeaheadFieldsetRemove');
            }

            vm.search = function (val) {
                if(!val || !val.val || (val.val != '*' && val.val.length < 3)) {
                    return [];
                }

                val.val = val.val == '*' ? '' : val.val;

                var origin = undefined;
                var objectType = undefined;

                switch (vm.type) {
                    case 'profiles':
                        origin = 'common';
                        objectType = 'Profile';
                }

                if(origin && objectType) {
                    return RestService.loadData(origin, objectType, val.val)
                        .then(function(result) {
                            var items = [];

                            if(result.data && result.data.content) {
                                _.forEach(JSOG.decode(result.data.content), function (value) {
                                    var name = value[Language.getActiveColumn()];

                                    if($linq(vm.contents).count("x => x.id == " + value.id) == 0) {
                                        items.push({ 'id': value.id, 'value': name, 'object': value });
                                    }
                                })
                            }

                            return items;
                        })
                }

                return [];
            }

            vm.addFunction = function () {
                if(vm.optionAdd) {
                    var itemSelected = { id: vm.optionAdd.id, name: vm.optionAdd.value, object: vm.optionAdd.object, deleted: false };
                    vm.contents.push(itemSelected);
                    vm.optionAdd = '';

                    $rootScope.$broadcast('annexaTypeaheadFieldsetAdd');
                }
            }


        }],
        bindings: {
            label: '@',
            placeholder: '@',
            type: '@',
            idForm: '@',
            propertyShowItem: '@',
            contents: '=',
            onlyOne: '=',
            showItemObjects: '='
        }
    })