/**
 * Created by osirvent on 20/03/2017.
 */
angular
    .module('annexaApp')
    .component('annexaMenu', {
        templateUrl: './components/common/annexa-menu/annexa-menu.html',
        controller: ['$rootScope', 'Language', function ($rootScope, Language) {
            var vm = this;

            var documentsByType = undefined;
            if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.documents_by_type){
                documentsByType = $rootScope.app.configuration.documents_by_type;
            }

            vm.languageColumn = Language.getActiveColumn();

            if(vm.items){
                vm.items.length = 0;
            }else{
                vm.items = [];
            }
            vm.menu = $linq(vm.menu).orderBy("x => x.moduleOrder").toArray();
            vm.removeModalOpen = function(){
                angular.element('body').removeClass('modal-open');
                angular.element('.modal-backdrop').remove();
            };

            var printMenu = function(menuAux){
            	_.forEach(menuAux, function(menuEntry) {
	            	if(menuEntry && menuEntry.url){
		                if(menuEntry.url == 'annexa.doc.documents.all' && documentsByType && documentsByType.active == 'true' && documentsByType.docTypes && documentsByType.docTypes.length > 0) {
		                    _.forEach(documentsByType.docTypes, function (docType) {
		                        if(docType && docType.type && docType.type.id){
		                            vm.items.push({
		                                url: 'annexa.doc.documents.type({type: ' + docType.type.id + '})',
		                                serviceName: undefined,
		                                iconType: docType.iconType.id,
		                                icon: docType.icon,
		                                language1: docType.type.language1,
		                                language2: docType.type.language2,
		                                language3: docType.type.language3,
		                                permissions: ['view_documents']
		                            })
		                        }
		                    });
		                }
		
		                vm.items.push(menuEntry);
	            	}
	            });
            }
            if(vm.items && vm.items.length == 0){
            	printMenu(vm.menu);
            }
            $rootScope.$on('printAnnexaMenu', function (event, args) {
        	    if(args && args.menu) {
        	    	if(vm.items && vm.items.length == 0){
        	    		printMenu(args.menu);
        	    	}
                }
            });
            
        }],
        bindings: {
            menu: '='
        }
    })