angular
    .module('annexaApp')
    .component('tableDataCommentColumn',{
        templateUrl: './components/common/table-data/table-data-comment-column.html',
        controller: ['RegModals', 'Language', '$rootScope', 'RestService', 'AnnexaModalFactory', function(RegModals, Language, $rootScope, RestService, AnnexaModalFactory) {
            var vm = this;

            var updateComments = function () {
                vm.data = ((vm.rowObject && vm.rowObject[vm.rowProperty]) ? vm.rowObject[vm.rowProperty] : ((!vm.rowObject && vm.rowProperty)?vm.rowProperty:undefined));
                vm.tooltip = vm.data ? vm.data : undefined;

                if(vm.data && Array.isArray(vm.data)) {
                    vm.tooltip = '';

                    _.forEach(vm.data, function(item) {
                        if(vm.commentProperty && item[vm.commentProperty]) {
                            if(vm.userProperty && item[vm.userProperty]) {
                                vm.tooltip += item[vm.userProperty]['name'] + ' ' + item[vm.userProperty]['surename1'] + (item[vm.userProperty]['surename2'] ? ' ' + item[vm.userProperty]['surename1'] : '') + ' - ';
                            }

                            vm.tooltip += item[vm.commentProperty] + '\n';
                        }
                    });
                }
            }

            this.$onInit = function() {
                updateComments();
            }

            vm.createComment = function() {
                switch (vm.type) {
                    case 'RegisterEntryDiligence':
                        var modal = angular.copy(RegModals.diligenceComment);
                        modal.alerts = [];
                        modal.data = vm.rowObject;
                        modal.languageColumn = Language.getActiveColumn();
                        modal.addDiligenceComment = function() {
                            var model = {
                                comments: modal.comment,
                                createdUser: { id: $rootScope.LoggedUser.id },
                                registerEntryDiligence: { id: vm.rowObject.id }
                            }

                            RestService.insert('./api/reg/register_entry_diligence_comment', model)
                                .then(function (data) {
                                    $rootScope.$broadcast('addedComment');
                                    modal.close();
                                }).catch(function(error) {
                                    console.log(error);
                            });
                        };

                        AnnexaModalFactory.showModal('modalEditTask', modal);
                        break;
                }
            }
        }],
        bindings:{
            rowObject: '=',
            rowProperty: '@',
            commentProperty: '@',
            userProperty: '@',
            canCreateComment: '=',
            type: '@'
        }
    })