angular
    .module('annexaApp')
    .component('annexaCustomFieldDefinition', {
        templateUrl: './components/common/annexa-custom-field-definition/annexa-custom-field-definition.html',
        controller:['$rootScope', 'RestService', 'CommonAdminService', 'Language', '$q', '$filter', 'CommonAdminModals', 'AnnexaFormlyFactory', 'ErrorFactory', 'HelperService', 'GlobalDataFactory', function ($rootScope, RestService, CommonAdminService, Language, $q, $filter, CommonAdminModals, AnnexaFormlyFactory, ErrorFactory, HelperService, GlobalDataFactory) {
            var vm = this;

            vm.editedIndex = undefined;
			
			vm.showCustomFieldType = false;
			
            vm.addedCustomField = undefined;

            vm.allCustomFields = [];

            vm.languageColumn = Language.getActiveColumn();
			
			vm.transactionTypes = [];
			
			vm.customFieldRequiredList = [
                { id: 'OPTIONAL', description: 'global.literals.OPTIONAL' },
                { id: 'REQUIRED', description: 'global.literals.REQUIRED' },
                { id: 'NO_EDITABLE', description: 'global.literals.NO_EDITABLE' }
            ];

			vm.customFieldProcedureType = [
                { id: 'DOSSIER', description: 'global.customFieldsTypes.DOSSIER' },
                { id: 'ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.ALL_DOSSIER_TRANSACTIONS' },
                { id: 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY', description: 'global.customFieldsTypes.ALL_DOSSIER_TRANSACTIONS_WITH_COPY' },
                { id: 'DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_TRANSACTIONS_TYPE' },
				{ id: 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' },
                { id: 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS' },
                { id: 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY' },
                { id: 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' },
                { id: 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' }

            ];

            var saveNewCustomField = function() {
                var self = this;
                var data = this.annexaFormly.model.modal_body;

                data.pressButton = true;

                if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                    this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
                }

                if(this.annexaFormly.form.$valid) {
                    var newCustomField = {
                        language1: data.language1,
                        language2: data.language2,
                        language3: data.language3,
                        backendType: data.backendType,
                        frontendType: data.frontendType,
                        listValues: data.listValues,
                        templateTag: data.templateTag
                    };

                    CommonAdminService.insertCustomField(newCustomField)
                        .then(function(data) {
                            addCF(JSOG.decode(data));

                            self.close();
                        }).catch(function(error) {
                            self.alerts.push({ msg: ErrorFactory.getErrorMessage('customFields', 'new', error) });
                    });
                } else {
                    if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$error.required) {
                        self.alerts.push({ msg: $filter('translate')('global.errors.noListValues')})
                    }
                }

                data.pressButton = undefined;

                if(this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                    this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
                }

            }

            var createNewCustomField = function() {
                var modal = CommonAdminModals.customFields;

                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { language1:'', language2:'', language3:'', frontendType:'', backendType:'', listValues:[] };
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false};

                AnnexaFormlyFactory.showModal('modalCustomField' + new Date().getTime(), modal, saveNewCustomField, false);
            }


            var addCF = function(newCustomField) {
                var toAddCustomField = {
                    customField: newCustomField,
                    required: false,
                    requiredForEndDossier: false,
                    hiddenField: false,
                    noEditable: false,
                    viewOrder: vm.customFields.length,
                    requiredString: 'OPTIONAL',
                    value: ''
                }
				if(vm.type == 'PROCEDURE' && vm.showCustomFieldType && !toAddCustomField.customFieldType){
					toAddCustomField.customFieldType = 'ALL_DOSSIER_TRANSACTIONS';
				}
                if(vm.updateOnChange && vm.parentProperty && vm.parentId) {
                    toAddCustomField[vm.parentProperty] = { id: vm.parentId }

                    vm.update(toAddCustomField);
                }

                vm.customFields.push(toAddCustomField);

                return toAddCustomField;
            }

            var saveEditCustomField = function() {
                if(vm.editedIndex != undefined) {
                    var self = this;
                    var data = this.annexaFormly.model.modal_body;

                    data.pressButton = true;

                    if (this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                        this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
                    }

                    if (this.annexaFormly.form.$valid) {
                        var disableElement = vm.customFields[vm.editedIndex].customField.disableElement;

                        var newCustomField = angular.copy(vm.customFields[vm.editedIndex].customField);
                        newCustomField.language1 = data.language1;
                        newCustomField.language2 = data.language2;
                        newCustomField.language3 = data.language3;
                        newCustomField.backendType = data.backendType;
                        newCustomField.frontendType = data.frontendType;
                        newCustomField.listValues = [];
                        newCustomField.templateTag = data.templateTag;

                        if(!data.listValues) {
                            _.forEach(vm.customFields[vm.editedIndex].customField.listValues, function (value) {
                                value.deleted = true;
                                value.removedDate = new Date();

                                newCustomField.listValues.push(value);
                            });
                        } else {
                            newCustomField.listValues = data.listValues;

                            _.forEach($linq(vm.customFields[vm.editedIndex].customField.listValues).except(data.listValues,"(x, y) => x.id == y.id").toArray(), function (value) {
                                value.deleted = true;
                                value.removedDate = new Date();

                                newCustomField.listValues.push(value);
                            });
                        }

                        CommonAdminService.updateCustomField(newCustomField)
                            .then(function(data) {
                                vm.customFields[vm.editedIndex].customField = JSOG.decode(data);
                                vm.customFields[vm.editedIndex].customField.disableElement = disableElement;
                                vm.editedIndex = undefined;
                                self.close();
                            }).catch(function (error) {
                                self.alerts.push({ msg: ErrorFactory.getErrorMessage('customFields', 'update', error) });
                        });
                    } else {
                        if (this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$error.required) {
                            self.alerts.push({msg: $filter('translate')('global.errors.noListValues')})
                        }
                    }

                    data.pressButton = undefined;

                    if (this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3']) {
                        this.annexaFormly.form['modal.annexaFormly.form_annexaLanguageFieldSet_listValues_3'].$validate();
                    }
                }
            }

            vm.editCustomField = function(index) {
                vm.editedIndex = index;

                var editCustomField = angular.copy(vm.customFields[index].customField);

                _.forEach(editCustomField.listValues, function(value, key) {
                    editCustomField.listValues[key].deleted = value.deleted;
                })

                editCustomField.view = editCustomField.disableElement;
                delete editCustomField.disableElement;

                var modal = CommonAdminModals.customFields;
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = editCustomField;
                modal.annexaFormly.options = { formState: { readOnly: editCustomField.view } };

                AnnexaFormlyFactory.showModal(
                    'modalCustomField' + new Date().getTime(),
                    modal,
                    saveEditCustomField,
                    false,
                    editCustomField.view && !_.contains(['SELECT', 'MULTIPLESELECT', 'CHECKBOX', 'RADIO'], editCustomField.frontendType)
                );
            }

            vm.addCustomField = function(newCustomField) {
                if(newCustomField.id != -1) {
                    addCF(newCustomField);

                    var indexAllcustomFields = $linq(vm.allCustomFields).indexOf("x => x.id == " + newCustomField.id);

                    if(indexAllcustomFields != -1) {
                        vm.allCustomFields.splice(indexAllcustomFields, 1);
                    }
                } else {
                    createNewCustomField();
                }

                vm.addedCustomField = undefined;
            }

            vm.removeCustomField = function(index) {
                var customFieldToRemove = {};
                if(index && vm.customFields){
                    customFieldToRemove = vm.customFields[index];
                }
                if(vm.updateOnChange) {
                    switch (vm.type) {
                        case 'DOCUMENT_TYPE':
                            RestService.delete('./api/doc/document_type/custom_field/' + vm.customFields[index].id)
                                .then(function (data) {
                                    vm.customFields.splice(index, 1);
                                    vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                                }).catch(function (error) {
                                    console.log(error);
                            });
                            break;
                        case 'TASK_TYPE':
                            RestService.delete('./api/task_type/custom_field/' + vm.customFields[index].id)
                                .then(function (data) {
                                    vm.customFields.splice(index, 1);
                                    vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                                    $rootScope.$broadcast('AnnexaTaskTypeCustomFieldDeleted', { item: customFieldToRemove });
                                }).catch(function (error) {
                                    console.log(error);
                            });
                            break;
                        case 'PROCEDURE':
                            var customField = vm.customFields[index];
                            customField.deleted = true;
                            CommonAdminService.insertOrUpdateProcedureCustomField(customField)
                                .then(function (data) {
                                    vm.customFields.splice(index, 1);
                                    vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                            }).catch(function (error) {
                                customField.deleted = false;
                                console.error(error);
                            });
                            break;
						case 'TRANSACTION_TYPE':
                            var customField = vm.customFields[index];
                            customField.deleted = true;
                            CommonAdminService.insertOrUpdateTransactionTypeCustomField(customField)
                                .then(function (data) {
                                    vm.customFields.splice(index, 1);
                                    vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                            }).catch(function (error) {
                                customField.deleted = false;
                                console.error(error);
                            });
                            break;
                    }
                } else {
                    vm.customFields.splice(index, 1);
                    vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                }
            }

            vm.addRemovedCustomFieldToAllCustomFields = function(customFieldToRemove){
                if(customFieldToRemove && customFieldToRemove.customField){
                    vm.allCustomFields.push(customFieldToRemove.customField);
                    vm.allCustomFields = $linq(vm.allCustomFields).orderBy("x => x." + vm.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                }
            }


            vm.sort = function(item, partFrom, partTo, indexFrom, indexTo){
                if(partTo){
                    _.forEach(partTo, function(value, key){
                        value.viewOrder = key + 1;

                        vm.update(value);
                    });
                }
            }

            vm.updateCustomField =  function(cfId, value){
                if(cfId) {
                    var cFs = $linq(vm.customFields).where("x => x.customField && x.customField.id == " + cfId).toArray();
                    if(cFs && cFs.length > 0){
                        if(value || value == ''){
                            cFs[0].value = value;
                        }
                        vm.update(cFs[0]);
                    }
                }
            }

            vm.update = function(customField) {
                customField.required = customField.requiredString == 'REQUIRED';
                customField.requiredForEndDossier = customField.requiredString == 'REQUIRED_TO_END';
                customField.hiddenField = customField.requiredString == 'HIDDEN_FIELD';
                customField.noEditable = customField.requiredString == 'NO_EDITABLE';
				if(vm.type == 'PROCEDURE' && vm.showCustomFieldType){
					customField.transactionTypes = [];
					if(customField.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE' || customField.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' || customField.customFieldType == 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' || customField.customFieldType == 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY'){
						if(customField.transactionTypesAux){
							_.forEach(customField.transactionTypesAux, function(tt){
								customField.transactionTypes.push({transactionType:{id:tt.id}});
							});
						}
					}else{
						if(customField.transactionTypesAux){
							customField.transactionTypesAux.length = 0;
						}else{
							customField.transactionTypesAux = [];
						}
					}
				}
                if(vm.updateOnChange) {
                    switch (vm.type) {
                        case 'DOCUMENT_TYPE':
                            if(customField.id) {
                                RestService.update('./api/doc/document_type/custom_field/' + customField.id, customField)
                                    .then(function (data) {
                                        //Empty
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            } else {
                                RestService.insert('./api/doc/document_type/custom_field', customField)
                                    .then(function (data) {
                                        vm.customFields[vm.customFields.length - 1].id = data.id;
                                        vm.customFields[vm.customFields.length - 1].customField.id = data.customField.id;
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            }
                            break;
                        case 'TASK_TYPE':
                            if(customField.id) {
                                RestService.update('./api/task_type/custom_field/' + customField.id, customField)
                                    .then(function (data) {
                                    	$rootScope.$broadcast('AnnexaTaskTypeCustomFieldUpdated', { item: customField });
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            } else {
                                RestService.insert('./api/task_type/custom_field', customField)
                                    .then(function (data) {
                                        vm.customFields[vm.customFields.length - 1].id = data.id;
                                        vm.customFields[vm.customFields.length - 1].customField.id = data.customField.id;
                                        $rootScope.$broadcast('AnnexaTaskTypeCustomFieldUpdated', { item: data });
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            }
                            break;
                        case 'PROCEDURE':
                            CommonAdminService.insertOrUpdateProcedureCustomField(customField)
                                .then(function (data) {
                                    if(customField && !customField.id){
                                        customField.id = data.id;
                                    }
                                }).catch(function (error) {
                                console.error(error);
                            });
                            break;
						case 'TRANSACTION_TYPE':
                            CommonAdminService.insertOrUpdateTransactionTypeCustomField(customField)
                                .then(function (data) {
                                    if(customField && !customField.id){
                                        customField.id = data.id;
                                    }
                                }).catch(function (error) {
                                console.error(error);
                            });
                            break;
                    }
                }
            }

            vm.showTools = function(customField, tool) {
                switch (tool) {
                    case 'edit':
                        if(!customField.customField.disableElement || _.contains(['SELECT', 'MULTIPLESELECT', 'CHECKBOX', 'RADIO'], customField.frontendType)) {
                            return true;
                        }

                        return false;
                    case 'see':
                        if(customField.customField.disableElement) {
                            return true;
                        }
                        return false;
                }
            }

            this.$onInit = function() {
                if(!vm.customFields) {
                    vm.customFields = [];
                }
				
				var promises = [
                    RestService.query('CustomField', 'findByOtherList', '{ in: true }')
                ];

                vm.allCustomFields = [
                    { id: '-1', value: '', language1: $filter('translate')('global.literals.new'), language2: $filter('translate')('global.literals.new'), language3: $filter('translate')('global.literals.new')}
                ];

				if(GlobalDataFactory.transactiontypes){
					vm.transactionTypes = angular.copy(GlobalDataFactory.transactiontypes);
				}
                $q.all(promises)
                    .then(function(data) {
                         var responseCF = GlobalDataFactory.customFields;
                         var responseCFDisabled = JSOG.decode(data[0].data);

                         _.forEach($linq(responseCF).orderBy("x => x." + vm.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray(), function(item) {
                             item.disableElement = $linq(responseCFDisabled).count("x => x.id == " + item.id) != 0;
                             if(vm.type == "DOCUMENT_TYPE" && item.backendType != "ADDRESS"){
                            	 vm.allCustomFields.push(item);
                             } else if(vm.type != "DOCUMENT_TYPE") {
                            	 vm.allCustomFields.push(item);
                             }
                         });

                         if(vm.allCustomFields && vm.customFields){
                             vm.allCustomFields = $linq(vm.allCustomFields).except(vm.customFields, function(x,y){
                                 if(x.customField && x.customField.id == y.id){
                                     return true;
                                 }
                                 return false;
                             }).toArray();
                         }
                    }).catch(function(error) {
                        console.error(error);
                });

                if(vm.type == 'PROCEDURE') {
                    vm.customFieldRequiredList.push({ id: 'REQUIRED_TO_END', description: 'global.literals.REQUIRED_TO_END' });
                    vm.customFieldRequiredList.push({ id: 'HIDDEN_FIELD', description: 'global.literals.HIDDEN_FIELD' });
					if(vm.showType && vm.showType === "true"){
						vm.showCustomFieldType = true;
						_.forEach(vm.customFields, function(customField){
							customField.transactionTypesAux = [];
							if(customField.transactionTypes){
								_.forEach(customField.transactionTypes, function(tt){
									if(tt.transactionType && tt.transactionType.id && vm.transactionTypes){
										var ttAux = $linq(vm.transactionTypes).firstOrDefault(undefined, "x => x.id ==" + tt.transactionType.id);
										if(ttAux){
											customField.transactionTypesAux.push(ttAux);
										}
									}
								});
							}
						});
					};
                }
            }
        }],
        bindings: {
            customFields: '=',
            type: '@',
            updateOnChange: '=',
            parentId: '=',
            parentProperty: '@',
			showType: '@?'
        }
    })