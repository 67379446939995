angular
    .module('annexaApp')
    .component('tableEditable', {
        templateUrl: './components/common/table-editable/table-editable.html',
        controller:['$filter', 'CommonService', '$rootScope', '$scope', function($filter, CommonService, $rootScope, $scope) {
            var vm = this;
			vm.dynamicLists = {};
            vm.changeOption = function(column, $data, itemIdAxu){
            	if(column.changeFunction){
            		if(column.changeDynamic){
						column.changeFunction($data, itemIdAxu, vm.dynamicLists);
					}else{
						column.changeFunction($data, itemIdAxu);
					}
            	}
            }
            
            vm.checkRequired = function($data, column, item) {
                if(column.required && !$data) {
                	return $filter('translate')('global.validation.required');
                }else if(column.checkRequiredFunction){
                	return column.checkRequiredFunction($data, item);
                }
            }
			
			
            vm.printUser = function(user) {
                if(user.value && user.user) {
                    return user.value;
                } else {
                    return 'global.documents.modal.sendToSign.empty';
                }
            }

            vm.loadUsers = CommonService.loadUsers;

            vm.inserted = {};

            vm.addItem = function() {
                vm.inserted = vm.newItem();
				vm.items.push(vm.inserted);
				if(vm.columns && vm.columns.length > 0){
            		_.forEach(vm.columns, function(column){
						if(column.type == "select-dynamic"){
            				vm.dynamicLists[column.id+'_'+vm.inserted[column.idItem]] = ((column.dynamicContent)?column.dynamicContent(vm.inserted):[]);
						}
					});
				}
            }

            vm.columnsWithoutActions = function () {
                return $linq(vm.columns).where("x => x.type != 'actions'").toArray();
            }

      		$scope.$on('tableEditableDynamicItemsFinished', function(event, args) {
				if(vm.columns && vm.columns.length > 0){
            		_.forEach(vm.columns, function(column){
						if(column.type == "select-dynamic" && vm.items && vm.items.length > 0){
							_.forEach(vm.items, function(it){
								if(it && it.idAux){
									vm.dynamicLists[column.id+'_'+it[column.idItem]] = ((column.dynamicContent)?column.dynamicContent(it):[]);
								}
							});
						}
            		})
            	}
             });	 
     
            this.$onInit = function() {
            	if(!vm.addLabel){
            		vm.addLabel = $filter('translate')('global.documents.modal.sendToSign.addUser');
            	}else{
            		vm.addLabel = $filter('translate')(vm.addLabel);
            	}
            	if(!vm.emptyLabel){
            		vm.emptyLabel = $filter('translate')('global.documents.modal.sendToSign.empty');
            	}else{
            		vm.emptyLabel = $filter('translate')(vm.emptyLabel);
            	}
            	if(vm.columns && vm.columns.length > 0){
            		_.forEach(vm.columns, function(item){
            			if((item.type == "select" || item.type == "select-dynamic") && !item.idProperty){
            				item.idProperty = 'id';
            			}
						if(item.type == "select-dynamic" && !item.idItem){
							item.idItem = 'id';
						}
						if(item.type == "select-dynamic" && vm.items && vm.items.length > 0){
							_.forEach(vm.items, function(it){
								if(it && it.idAux){
									vm.dynamicLists[item.id+'_'+it[item.idItem]] = ((item.dynamicContent)?item.dynamicContent(it):[]);
								}
							});
						}
            		})
            	}
            }
        }],
        bindings: {
            columns: '=',
            items: '=',
            orderBy: '@',
            update: '=',
            newItem: '=',
            removeItem: '=',
            addLabel: '=?',
            emptyLabel: '=?'
        }
    })