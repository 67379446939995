angular
    .module('annexaApp')
    .component('annexaObject',{
        transclude: {
            'previousSlot': '?previous'
        },
        templateUrl: './components/common/annexa-object/annexa-object.html',
        controller:[function() {
            var vm = this;

            vm.closeAlert = function (index) {
                if(vm.alerts) {
                    vm.alerts.splice(index, 1);
                }
            }
        }],
        bindings:{
            id: '@',
            objectTitle: '@',
            fieldsDefinition: '=',
            submit: '=',
            alerts: '=',
            isEdit: '=',
            permissions: '=',
            object: '=?',
            isModal: '='
        }
    })