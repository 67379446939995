angular
    .module('annexaApp')
    .component('annexaBoxChart',{
        transclude: true,
        templateUrl: './components/common/annexa-box-chart/annexa-box-chart.html',
        controller: ['$location', '$anchorScroll', '$rootScope', 'Language', 'AnnexaBIFactory', function ($location, $anchorScroll, $rootScope, Language, AnnexaBIFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.scrollTo = function (id) {
                $location.hash(id);
                $anchorScroll();
            }

            vm.selected = 0;
            vm.filterOptions = [];
            vm.changeSelected = function(item) {
                $rootScope.$broadcast('annexaBoxChartFilter', { filterId: vm.filterId, item: item });
            }

            vm.assignLeftRightValues = function (chart, left, right, values) {
                if(!values) {
                    values = AnnexaBIFactory.BIValues;
                }

                chart.leftValue = values[left];
                chart.rightValue = values[right];

                return chart;
            }

            vm.updateTimelineLeftRightDependant = function(chart, data, index, left, right) {
                chart.leftValue = $linq(data[index].series[left].data).sum("x => x.value");
                chart.rightValue = $linq(data[index].series[right].data).sum("x => x.value");

                return chart;
            }

            vm.refreshChart = function (containerId) {
                $rootScope.$broadcast('annexaChartRefresh', { containerId: containerId });
            }
        }],
        bindings: {
            icon: '@',
            title: '@',
            scrollId: '@?',
            scrollText: '@?',
            filterId: '@?'

        }
    })