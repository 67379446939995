angular
    .module('annexaApp')
    .factory('annexaDashboardFactory',['$q', '$http', 'GlobalDataFactory', '$filter', 'Language', '$rootScope', function($q, $http, GlobalDataFactory, $filter, Language, $rootScope) {
        var factory = {};

        factory.documentCounters = [];
        factory.tramCounters = [];
        factory.regCounters = [];
        factory.taskFullCounters = [];
        factory.taskSimpleCounters = [];
        factory.archiveCounters = [];

        factory.loadDashboard = function() {
        	var deferrend = $q.defer();
        	factory.documentCounters = [];
            factory.tramCounters = [];
            factory.regCounters = [];
            factory.taskFullCounters = [];
            factory.taskSimpleCounters = [];
            factory.archiveCounters = [];
            
            var docStatuses = $linq(GlobalDataFactory.documentStatuses).where("x => x.id != ''").orderBy("x => x.orderStatus").toArray();

            _.forEach(docStatuses, function(item) {
            	var dsTrobat = $linq(factory.documentCounters).firstOrDefault(undefined, "x => x.stateParams && x.stateParams.status == '"+item.id+"'");
            	if(!dsTrobat && !item.hide){
                    factory.documentCounters.push({
                        state: 'annexa.doc.documents.all',
                        stateParams: { status: item.id },
                        icon: item.icon,
                        style: item.style,
                        title: 'global.documentState.' + item.id,
                        number: ''
                    })
            	}
            })
            
            var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.tramPendingTramitation'");
        	if(!dsTrobat){
                factory.tramCounters.push({
                    state: 'annexa.tram.pending',
                    stateParams: undefined,
                    title: 'global.literals.tramPendingTramitation',
                    number: ''
                });
        	}
        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.outOfTime'");
        	if(!dsTrobat){
                factory.tramCounters.push({
                    state: 'annexa.tram.pending',
                    stateParams: { type: 'expired' },
                    title: 'global.literals.outOfTime',
                    number: ''
                });
        	}
        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.tramFavorite'");
        	if(!dsTrobat){
                factory.tramCounters.push({
                    state: 'annexa.tram.pending',
                    stateParams: { type: 'favorit' },
                    title: 'global.literals.tramFavorite',
                    number: ''
                });
        	}
        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.tramPendingSee'");
        	if(!dsTrobat){
                factory.tramCounters.push({
                    state: 'annexa.tram.view',
                    stateParams: { type: 'tramitation' },
                    title: 'global.literals.tramPendingSee',
                    number: ''
                });
        	}
        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.tramFinally'");
        	if(!dsTrobat){
                factory.tramCounters.push({
                    state: 'annexa.tram.view',
                    stateParams: { type: 'finally' },
                    title: 'global.literals.tramFinally',
                    number: ''
                });
        	}
        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.title == 'global.tram.tabs.dossierRightAccess.title'");
        	if(!dsTrobat){
                factory.tramCounters.push({
                    state: 'annexa.tram.dossierRightAccess',
                    title: 'global.tram.tabs.dossierRightAccess.title',
                    number: ''
                });
        	}
        	var diligenceTypes = GlobalDataFactory.registerDiligenceTypes;
            _.forEach(diligenceTypes, function(diligenceType) {
            	var titleDilig = $filter('translate')('global.literals.dashboardDiligenceType', { diligenceType: diligenceType[Language.getActiveColumn()]});
            	var dsTrobat = $linq(factory.regCounters).firstOrDefault(undefined, "x => x.title == '"+titleDilig+"'");
            	if(!dsTrobat){
            		factory.regCounters.push({
                        permissions: ['view_input_register', 'diligence_input_register','view_all_diligences','view_all_input_register'],
                        state: 'annexa.reg.input.diligences',
                        stateParams: { diligenceType: diligenceType.id, diligenceStatus: ((diligenceType.canAppendDossier || diligenceType.canStartDossier)?'PENDING_ACCEPT':'PENDING_RECEPT') },
                        title: titleDilig,
                        number: ''
                    });
            	}
            })
            var item = {
                state: 'annexa.tasks.assignedTasks',
                stateParams: {type: 'ANE'},
                title: 'global.literals.withinDeadline',
                column: 1,
                number: '',
                isChild: true
            };
            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.withinDeadline'");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
        	var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.withinDeadline'");
        	if(!dsTrobat){
        		factory.taskSimpleCounters.push(item);
        	}
            var item = {
                state: 'annexa.tasks.assignedTasks',
                stateParams: {type: 'AE'},
                title: 'global.literals.outOfTime',
                column: 1,
                number: '',
                isChild: true
            };

            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.outOfTime'");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
            var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.outOfTime'");
        	if(!dsTrobat){
        		factory.taskSimpleCounters.push(item);
        	}
            var item = {
                state: 'annexa.tasks.assignedTasks',
                stateParams: {type: 'AP'},
                title: 'global.task.pendingTasks',
                column: 1,
                number: '',
                isChild: true
            };

            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.task.pendingTasks'");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
        	var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.title == 'global.task.pendingTasks'");
        	if(!dsTrobat){
        		factory.taskSimpleCounters.push(item);
        	}
            var item = {
                state: 'annexa.tasks.assignedTasks',
                stateParams: {type: 'A'},
                title: 'global.task.myTask',
                column: 1,
                number: '',
                isGroup: true
            };
            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.task.myTask'");
        	if(!dsTrobat){
        		factory.taskFullCounters.unshift(item);
        	}
        	var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.title == 'global.task.myTask'");
        	if(!dsTrobat){
        		factory.taskSimpleCounters.unshift(item);
        	}
        	var item = {
                state: 'annexa.tasks.createdTasks',
                stateParams: {type: 'C'},
                title: 'global.task.createdTask',
                column: 2,
                number: '',
                isGroup: true
            };
            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.task.createdTask' && x.column == 2");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
            var item ={
                state: 'annexa.tasks.createdTasks',
                stateParams: {type: 'CNE'},
                title: 'global.literals.withinDeadline',
                column: 2,
                number: '',
                isChild: true
            };

            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.withinDeadline' && x.column == 2");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
            var item = {
                state: 'annexa.tasks.createdTasks',
                stateParams: {type: 'CE'},
                title: 'global.literals.outOfTime',
                column: 2,
                number: '',
                isChild: true
            };

            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.literals.outOfTime' && x.column == 2");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
            var item = {
                state: 'annexa.tasks.createdTasks',
                stateParams: {type: 'CP'},
                title: 'global.task.pendingTasks',
                column: 2,
                number: '',
                isChild: true
            };

            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.task.pendingTasks' && x.column == 2");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
            var item = {
                state: 'annexa.tasks.createdTasks',
                stateParams: {type: 'CF'},
                title: 'global.task.finalizedTasks',
                column: 2,
                number: '',
                isChild: true
            };

            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.title == 'global.task.finalizedTasks' && x.column == 2");
        	if(!dsTrobat){
        		factory.taskFullCounters.push(item);
        	}
        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
	    		var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.title == 'global.archive.finally.title'");
	        	if(!dsTrobat){
	                factory.archiveCounters.push({
	                    state: 'annexa.archive.finally',
	                    title: 'global.archive.finally.title',
	                    number: '',
	                    permissions: ['archive_finally']
	                });
	        	}
	        	var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.title == 'global.archive.close.title'");
	        	if(!dsTrobat){
	                factory.archiveCounters.push({
	                    state: 'annexa.archive.close',
	                    title: 'global.archive.close.title',
	                    number: '',
	                    permissions: ['archive_close']
	                });
	        	}
        		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
		        	var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.title == 'global.archive.transfer.title'");
		        	if(!dsTrobat){
		                factory.archiveCounters.push({
		                    state: 'annexa.archive.transfer',
		                    title: 'global.archive.transfer.title',
		                    number: '',
		                    permissions: ['archive_transfer']
		                });
		        	}
		        	var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.title == 'global.archive.rejected.title'");
		        	if(!dsTrobat){
		                factory.archiveCounters.push({
		                    state: 'annexa.archive.rejected',
		                    title: 'global.archive.rejected.title',
		                    number: '',
		                    permissions: ['archive_rejected']
		                });
		        	}
        		}
        		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
		        	var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.title == 'global.archive.archive.title'");
		        	if(!dsTrobat){
		                factory.archiveCounters.push({
		                    state: 'annexa.archive.archive',
		                    title: 'global.archive.archive.title',
		                    number: '',
		                    permissions: ['archive_archive']
		                });
		        	}
        		}
        	}
            $http({ method: 'GET', url: './api/doc/PENDING_SIGN/status/count' }).then(function(data) {
            	if(factory.documentCounters){
            		_.forEach(factory.documentCounters, function(item) {
            			if(item.stateParams && item.stateParams.status == 'PENDING_SIGN'){
            				item.number = (data.data && data.data > 0 ? data.data : '');
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/tram/counters/PendingTramitation' }).then(function(data) {
            	if(factory.tramCounters){
            		_.forEach(factory.tramCounters, function(item) {
            			if(item.title && item.title == 'global.literals.tramPendingTramitation'){
            				item.number = ((data.data && data.data.hasOwnProperty("PendingTramitation") && data.data.PendingTramitation > 0 )? data.data.PendingTramitation : '');
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/tram/counters/Expired' }).then(function(data) {
            	if(factory.tramCounters){
            		_.forEach(factory.tramCounters, function(item) {
            			if(item.title && item.title == 'global.literals.outOfTime'){
            				item.number = ((data.data && data.data.hasOwnProperty("Expired") && data.data.Expired > 0 )? data.data.Expired : '');
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/tram/counters/PendingSee' }).then(function(data) {
            	if(factory.tramCounters){
            		_.forEach(factory.tramCounters, function(item) {
            			if(item.title && item.title == 'global.literals.tramPendingSee'){
            				item.number = ((data.data && data.data.hasOwnProperty("PendingSee") && data.data.PendingSee > 0 )? data.data.PendingSee : '');
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/tram/counters/Finally' }).then(function(data) {
            	if(factory.tramCounters){
            		_.forEach(factory.tramCounters, function(item) {
            			if(item.title && item.title == 'global.literals.tramFinally'){
            				item.number = ((data.data && data.data.hasOwnProperty("Finally") && data.data.Finally > 0 )? data.data.Finally : '');
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/tram/counters/Favorite' }).then(function(data) {
            	if(factory.tramCounters){
            		_.forEach(factory.tramCounters, function(item) {
            			if(item.title && item.title == 'global.literals.tramFavorite'){
            				item.number = ((data.data && data.data.hasOwnProperty("Favorite") && data.data.Favorite > 0 )? data.data.Favorite : '');
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/reg/dashboard/counters' }).then(function(data) {
            	if(factory.regCounters){
            		_.forEach(factory.regCounters, function(item) {
            			if(data && data.data && item.stateParams && item.stateParams.diligenceType && data.data.hasOwnProperty("DiligenceType_"+item.stateParams.diligenceType)){
            				if(data.data["DiligenceType_"+item.stateParams.diligenceType] > 0){
            					item.number = data.data["DiligenceType_"+item.stateParams.diligenceType]; 
            				}
            			}
            		});
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/tram/counters/RightAccess' }).then(function(data) {
            	if(factory.tramCounters){
            		_.forEach(factory.tramCounters, function(item) {
            			if(item.title && item.title == 'global.tram.tabs.dossierRightAccess.title'){
            				item.number = ((data.data && data.data.hasOwnProperty("RightAccess") && data.data.RightAccess > 0 )? data.data.RightAccess : '');
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            $http({ method: 'GET', url: './api/tasks/dashboard/counters/full' }).then(function(data) {
            	var total1 = 0;
                var total2 = 0;
                if(factory.taskFullCounters){
            		_.forEach(factory.taskFullCounters, function(item) {
            			if(item.title && item.title == 'global.literals.withinDeadline' && item.column == 1){
            				item.number = ((data.data && data.data.hasOwnProperty("AssignedNoExpired") && data.data.AssignedNoExpired > 0 )? data.data.AssignedNoExpired : '');
            				total1 += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.literals.outOfTime' && item.column == 1){
            				item.number = ((data.data && data.data.hasOwnProperty("AssignedExpired") && data.data.AssignedExpired > 0 )? data.data.AssignedExpired : '');
            				total1 += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.task.pendingTasks' && item.column == 1){
            				item.number = ((data.data && data.data.hasOwnProperty("AssignedPending") && data.data.AssignedPending > 0 )? data.data.AssignedPending : '');
            				total1 += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.literals.withinDeadline' && item.column == 2){
            				item.number = ((data.data && data.data.hasOwnProperty("CreatedNoExpired") && data.data.CreatedNoExpired > 0)? data.data.CreatedNoExpired : '');
            				total2 += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.literals.outOfTime' && item.column == 2){
            				item.number = ((data.data && data.data.hasOwnProperty("CreatedExpired") && data.data.CreatedExpired > 0 )? data.data.CreatedExpired : '');
            				total2 += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.task.pendingTasks' && item.column == 2){
            				item.number = ((data.data && data.data.hasOwnProperty("CreatedPending") && data.data.CreatedPending > 0 )? data.data.CreatedPending : '');
            				total2 += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.task.finalizedTasks' && item.column == 2){
            				item.number = ((data.data && data.data.hasOwnProperty("CreatedFinished") && data.data.CreatedFinished > 0)? data.data.CreatedFinished : '');
            				total2 += ((item.number && item.number != '')?item.number:0);
            			}
            		})
            		_.forEach(factory.taskFullCounters, function(item) {
            			if(item.title && item.title == 'global.task.myTask' && item.column == 1 && total1 > 0){
            				item.number = total1;
            			} else if(item.title && item.title == 'global.task.createdTask' && item.column == 2 && total2 > 0){
            				item.number = total2;
            			}
            		})
            	}
                var total = 0;
                if(factory.taskSimpleCounters){
            		_.forEach(factory.taskSimpleCounters, function(item) {
            			if(item.title && item.title == 'global.literals.withinDeadline' && item.column == 1){
            				item.number = ((data.data && data.data.hasOwnProperty("AssignedNoExpired") && data.data.AssignedNoExpired > 0 )? data.data.AssignedNoExpired : '');
            				total += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.literals.outOfTime' && item.column == 1){
            				item.number = ((data.data && data.data.hasOwnProperty("AssignedExpired") && data.data.AssignedExpired > 0 )? data.data.AssignedExpired : '');
            				total += ((item.number && item.number != '')?item.number:0);
            			} else if(item.title && item.title == 'global.task.pendingTasks' && item.column == 1){
            				item.number = ((data.data && data.data.hasOwnProperty("AssignedPending") && data.data.AssignedPending > 0 )? data.data.AssignedPending : '');
            				total += ((item.number && item.number != '')?item.number:0);
            			}
            		})
            		_.forEach(factory.taskSimpleCounters, function(item) {
            			if(item.title && item.title == 'global.task.myTask' && item.column == 1 && total > 0){
            				item.number = total;
            			}
            		})
            	}
            }).catch(function(error) {
            	
            });
            if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
	            $http({ method: 'GET', url: './api/tram/archive/finallyCounters' }).then(function(data) {
	            	if(factory.archiveCounters){
	            		_.forEach(factory.archiveCounters, function(item) {
	            			if(item.title && item.title == 'global.archive.finally.title'){
	            				item.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
	            			}
	            		})
	            	}
	            }).catch(function(error) {
	            	
	            });
	            $http({ method: 'GET', url: './api/tram/archive/closeCounters' }).then(function(data) {
	            	if(factory.archiveCounters){
	            		_.forEach(factory.archiveCounters, function(item) {
	            			if(item.title && item.title == 'global.archive.close.title'){
	            				item.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
	            			}
	            		})
	            	}
	            }).catch(function(error) {
	            	
	            });
	            if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
			        $http({ method: 'GET', url: './api/tram/archive/transferCounters' }).then(function(data) {
		            	if(factory.archiveCounters){
		            		_.forEach(factory.archiveCounters, function(item) {
		            			if(item.title && item.title == 'global.archive.transfer.title'){
		            				item.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
		            			}
		            		})
		            	}
		            }).catch(function(error) {
		            	
		            });
		            $http({ method: 'GET', url: './api/tram/archive/rejectCounters' }).then(function(data) {
		            	if(factory.archiveCounters){
		            		_.forEach(factory.archiveCounters, function(item) {
		            			if(item.title && item.title == 'global.archive.reject.title'){
		            				item.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
		            			}
		            		})
		            	}
		            }).catch(function(error) {
		            	
		            });
            	}
	            if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
			        $http({ method: 'GET', url: './api/tram/archive/archiveCounters' }).then(function(data) {
		            	if(factory.archiveCounters){
		            		_.forEach(factory.archiveCounters, function(item) {
		            			if(item.title && item.title == 'global.archive.archive.title'){
		            				item.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
		            			}
		            		})
		            	}
		            }).catch(function(error) {
		            	
		            });
	            }
            }
        	deferrend.resolve('OK');
            return deferrend.promise;
            
        }

        return factory;
    }])
    .component('annexaDashboard', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard.html',
        controller:['annexaDashboardFactory', 'GlobalDataFactory', function(annexaDashboardFactory, GlobalDataFactory) {
            var vm = this;

            vm.documentCounters = annexaDashboardFactory.documentCounters
            vm.tramCounters = annexaDashboardFactory.tramCounters;
            vm.regCounters = annexaDashboardFactory.regCounters;
            vm.taskFullCounters = annexaDashboardFactory.taskFullCounters;
            vm.taskSimpleCounters = annexaDashboardFactory.taskSimpleCounters;
            vm.archiveCounters = annexaDashboardFactory.archiveCounters;

            vm.docModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.doc.documents.all'");
            vm.signModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.sign.pending'");
            vm.tramModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.tram'");
            vm.regModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.reg.input'");
            vm.taskModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.tasks'");
            vm.archiveModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.archive'");
        }]
    })
    .component('annexaDashboardBox', {
        transclude: true,
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-box.html',
        controller: [function() {
            var vm = this;

            vm.columnOneCounters = [];
            vm.columnTwoCounters = []

            this.$onInit = function () {
                if(vm.twoColumns) {
                    vm.columnOneCounters = $linq(vm.counters).where("x => x.column == 1").toArray();
                    vm.columnTwoCounters = $linq(vm.counters).where("x => x.column == 2").toArray();
                }
            }
        }],
        bindings: {
            headState: '@',
            headParameters: '=?',
            iconType: '@',
            icon: '@',
            titleToShow: '@',
            counters: '=',
            twoColumns: '='
        }
    })
    .component('annexaDashboardBoxContent', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-box-content.html',
        controller: [function() {
            var vm = this;
        }],
        bindings: {
            counters: '='
        }
    })
    .component('annexaDashboardCounter', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-counter.html',
        controller:[function() {
            var vm = this;
        }],
        bindings: {
            number: '@'
        }
    })
    .component('annexaDashboardSign',{
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-sign.html',
        controller: ['GlobalDataFactory', 'Language', '$http', function(GlobalDataFactory, Language, $http) {
            var vm = this;

            vm.signModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.sign.pending'");
            vm.signActionTypes = angular.copy(GlobalDataFactory.signActionTypes);
            vm.languageColumn = Language.getActiveColumn();

            _.forEach(vm.signActionTypes, function (item, index) {
                $http({
                    method: 'GET',
                    url: './api/signprocessactions/countPending/' + item.id
                }).then(function(data) {
                    vm.signActionTypes[index].num = data.data > 0 ? data.data : '';
                }).catch(function (error) {
                    console.error(error);
                });
            });

            $http({
                method: 'GET',
                url: './api/signprocessactions/countPending/urgent'
            }).then(function(data) {
                vm.numUrgents = data.data > 0 ? data.data : '';
            }).catch(function (error) {
                console.error(error);
            });
        }]
    });