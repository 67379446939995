/**
 * Created by osirvent on 18/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaEntityComponent', {
        templateUrl: './components/common/annexa-entity-component/annexa-entity-component.html',
        controller: ['$timeout', '$state', '$rootScope','$scope', function($timeout, $state, $rootScope, $scope) {
            var vm = this;

            vm.saveEntity = function () {
                vm.form.extra = vm.boxes;

                $timeout(function () {
                    angular.element('#smEntityForm' + vm.id).trigger('click');
                });
            }

            $scope.$on('saveEntityComponent', function(event, args) {
                vm.saveEntity();
            });

            vm.cancelEntity = function () {
                if(vm.cancelState) {
                    $state.transitionTo(vm.cancelState);
                }
            }

            $scope.$on('cancelEntityComponent', function(event, args) {
                vm.cancelEntity();
            });

            vm.closeAlert = function(index) {
                if(vm.modal && vm.modal.alerts) {
                    vm.modal.alerts.splice(index, 1);
                } else if(vm.form && vm.form.alerts) {
                    vm.form.alerts.splice(index, 1);
                }
            }
        }],
        bindings: {
            id: '@',
            title: '@?',
            form: '=?',
            modal: '=?',
            boxes: '=',
            cancelState: '@?'
        }
    })