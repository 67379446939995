angular
    .module('annexaApp')
    .component('annexaEditableCustomField',{
        templateUrl: './components/common/annexa-editable-custom-fields/annexa-editable-custom-fields.html',
        controller:['Language', function(Language) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

        }],
        bindings: {
            customFields: '='
        }
    })
