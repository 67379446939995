angular
    .module('annexaApp')
    .component('annexaAddressPseudoVies', {
        templateUrl: './components/common/annexa-address-pseudo-vies/annexa-address-pseudo-vies.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addPseudoVies = function () {
            	var modal = angular.copy(CommonAdminModals.cityPseudoViesNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
                if(vm.pseudoVies && vm.pseudoVies.length > 0){
                	modal.annexaFormly.fields[0].templateOptions.actualPseudoVies = $linq(vm.pseudoVies).where("x => x.pseudoVia && x.pseudoVia.id").select("x => x.pseudoVia.id").toArray();
                }else{
                	modal.annexaFormly.fields[0].templateOptions.actualPseudoVies = [];
                }
                AnnexaFormlyFactory.showModal('modalNewPseudoVies', modal, vm.savePseudoVies, false);
            }
            
            vm.savePseudoVies = function(pseudoVia){
            	if(pseudoVia && pseudoVia.annexaFormly && pseudoVia.annexaFormly.model){
            		var model = {
            			pseudoVia:pseudoVia.annexaFormly.model.pseudoVia
            		}
		        	if(vm.cityId){
		        		model.city = {id:vm.cityId};
            		}
            		if(!vm.isNew){
            			RestService.insert('./api/territory/street_city_pseudo_via', model).then(function(data) {
	        				if(data){
	                            vm.pseudoVies.push(JSOG.decode(data));	        					
		        			}
	        				pseudoVia.close();
                        }).catch(function() {
                        	pseudoVia.alerts.push("Error");
                        });
		        	}else{
		        		vm.pseudoVies.push(model);
		        		pseudoVia.close();
		        	}
            	}
            }

            vm.editPseudoVia = function (pseudoVia, index) {
            	var modal = angular.copy(CommonAdminModals.cityPseudoViesNew);
            	var pseudoViaToUpdate = angular.copy(pseudoVia);
            	modal.extra = {
            		pseudoVia:pseudoVia,
	            	index:index
            	}
            	modal.annexaFormly.model = pseudoViaToUpdate;
            	modal.annexaFormly.options = { watchAllExpressions: true };
            	if(vm.pseudoVies && vm.pseudoVies.length > 0){
                	modal.annexaFormly.fields[0].templateOptions.actualPseudoVies = $linq(vm.pseudoVies).where("x => x.pseudoVia && x.pseudoVia.id").select("x => x.pseudoVia.id").toArray();
                }else{
                	modal.annexaFormly.fields[0].templateOptions.actualPseudoVies = [];
                }
            	AnnexaFormlyFactory.showModal('modalupdatePseudoVies', modal, vm.updatePseudoVies, false);
            }
            
            vm.updatePseudoVies = function(pseudoVia){
            	if(pseudoVia.extra.pseudoVia){
	            	if(pseudoVia && pseudoVia.annexaFormly && pseudoVia.annexaFormly.model){
			        	if(!vm.isNew){
			        		var pseudoViaAux = angular.copy(pseudoVia.extra.pseudoVia);
			        		pseudoViaAux.pseudoVia = pseudoVia.annexaFormly.model.pseudoVia;
		            		if(vm.cityId){
		            			pseudoViaAux.city = {id:vm.cityId};
		            		}
		            		RestService.update('./api/territory/street_city_pseudo_via/' + pseudoViaAux.id, pseudoViaAux).then(function (data) {
                    			if(data){
                    				var decoded = JSOG.decode(data);
                    				pseudoVia.extra.pseudoVia.pseudoVia = decoded.pseudoVia;
                    				pseudoVia.extra.pseudoVia.city = decoded.city; 
                    			}
                    			pseudoVia.close();
                            }).catch(function () {
                            	pseudoVia.alerts.push("Error");
                            });
			        	}else{
			        		if(pseudoVia.annexaFormly.model.pseudoVia){
			        			pseudoVia.extra.pseudoVia.pseudoVia = pseudoVia.annexaFormly.model.pseudoVia;  
		            		}
			        		pseudoVia.close();
			        	}
	            	}
            	}
            }
            
            vm.deletePseudoVies = function (pseudoVia, index) {
               	DialogsFactory.confirm('global.territory.list.confirmRemovePseudoViesTitle', 'global.territory.list.confirmRemovePseudoViesBody').then(function (dataAux) {
           			if(pseudoVia){
		        		var pseudoViaIndex = -1;
		        		if(pseudoVia.id){
		        			pseudoViaIndex = $linq(vm.pseudoVies).indexOf("x => x.id == "+pseudoVia.id);
		        		}else{
		        			pseudoViaIndex = index;
		        		}
		        		if(pseudoViaIndex > -1){
				        	if(!vm.isNew){
				        		if(pseudoVia && pseudoVia.id && ( pseudoViaIndex || pseudoViaIndex == 0) && vm.pseudoVies && vm.pseudoVies.length >= pseudoViaIndex){
		                    		RestService.delete('./api/territory/street_city_pseudo_via/' + pseudoVia.id).then(function (data) {
		                    			vm.pseudoVies.splice(pseudoViaIndex, 1);
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		                        }
				        	}else{
				        		vm.pseudoVies.splice(pseudoViaIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
            }
            
        }],
        bindings: {
        	pseudoVies: '=',
            isNew: '=',
            cityId:'=?'
        }
    })