angular
    .module('annexaApp')
    .filter('startFrom', function() {
        return function(input, start) {
            start = +start; //parse to int
            return input.slice(start);
        }
    })
    .factory('AnnexaObjectBoxFactory',[function() {
        var factory = {};

        factory.addNewViewPermissions = function (permissions, new_permission, view_permission, isEdit) {
            if(!permissions) {
                permissions = !isEdit ? {new: new_permission, view: view_permission} : {};
            } else {
                if(!permissions.new) {
                    if(!isEdit) {
                        permissions.new = new_permission
                    }
                } else {
                    if(!Array.isArray(permissions.new)) {
                        permissions.new = [permissions.new];
                    }

                    if(!Array.isArray(new_permission)) {
                        if ($linq(permissions.new).count("x => x =='" + new_permission + "'") == 0) {
                            permissions.new.push(new_permission);
                        }
                    } else {
                        _.forEach(new_permission, function (item) {
                           if($linq(permissions.new).count("x => x =='" + item + "'") == 0) {
                               permissions.new.push(item);
                           }
                        });
                    }
                }

                if(!permissions.view) {
                    if(!isEdit) {
                        permissions.view = view_permission
                    }
                } else {
                    if(!Array.isArray(permissions.view)) {
                        permissions.view = [permissions.view];
                    }

                    if(!Array.isArray(view_permission)) {
                        if($linq(permissions.view).count("x => x =='" + view_permission + "'") == 0) {
                            permissions.view.push(view_permission);
                        }
                    } else {
                        _.forEach(view_permission, function (item) {
                            if($linq(permissions.view).count("x => x =='" + item + "'") == 0) {
                                permissions.view.push(item);
                            }
                        });
                    }
                }
            }

            return permissions;
        }

        return factory;
    }])
    .component('annexaObjectBox', {
        transclude: true,
        templateUrl: './components/common/annexa-object-box/annexa-object-box.html',
        controller:['AnnexaPermissionsFactory', '$rootScope', 'RestService', 'AnnexaFormlyFactory', '$scope', 'Language', function(AnnexaPermissionsFactory, $rootScope, RestService, AnnexaFormlyFactory, $scope, Language) {
            var vm = this;

            var havePermission = function(permissionProperty) {
                if(vm.permissions && vm.permissions[permissionProperty]) {
                    var permissions = !Array.isArray(vm.permissions[permissionProperty]) ? [vm.permissions[permissionProperty]] : vm.permissions[permissionProperty];

                    var have = false;

                    _.forEach(permissions, function(permission) {
                        have = have || AnnexaPermissionsFactory.haveSinglePermissionDual(permission, vm.profiles);
                    });

                    return have;
                }

                return false;
            }

            vm.haveNewPermission = function() {
                return havePermission('new');
            }

            vm.haveViewPermission = function() {
                return havePermission('view');
            }

            vm.haveAllPermissions = function () {
                return vm.allPermissions && vm.allPermissions.length > 0;
            }

            this.$onInit = function () {
				if(!vm.newTitle){
					vm.newTitle = 'global.literals.new';
				}
                if(vm.search) {
                    vm.search.searchedValue = undefined;

                    if (vm.search.selectFunc && !vm.search.typeaheadSelect) {
                        vm.search.typeaheadSelect = function (item) {
                            if (item && item.value.indexOf('</i>') > 0) {
                                item.value = item.value.substring(item.value.indexOf('</i>') + 4, item.value.length);
                            }
                        }
                    } else {
                        vm.search.typeaheadSelect = function (item) {
                            if (item && item.value.indexOf('</i>') > 0) {
                                item.value = item.value.substring(item.value.indexOf('</i>') + 4, item.value.length);
                            }
                        }
                    }

                    if(!vm.search.addText) {
                        vm.search.addText = 'global.literals.add';
                    }

                    if(!vm.search.addedSelect) {
                        vm.search.addedSelect = 'x => x.id';
                    }
                    if(!vm.search.searchFunc) {
                        vm.search.searchFunc = function(val) {
                        	var added = [];
                            if(vm.search.addedSelect != 'NoSelect') {
                            	added = $linq(vm.content).select(vm.search.addedSelect).toArray();
                            }

                            var filter = { name: val.val };

                            if(vm.search.customFilter) {
                                var pareseableCustomFilter = vm.search.customFilter.replace('@VAL@', val.val);
                                pareseableCustomFilter = pareseableCustomFilter.replace('@VAL@', val.val);
                                filter = JSON.parse(pareseableCustomFilter);
                            }

                            var additionalFilter = vm.search.additionalFilter ? vm.search.additionalFilter : {};
                            additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                            additionalFilter.added = added;
                            additionalFilter.langColumn = Language.getActiveColumn();
                            
                            if(vm.search.type && vm.search.type == "Address") {
                            	additionalFilter.active = "1";
                            }

                            return RestService.filterData(vm.search.origin, vm.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
                                .then(function (data) {
                                    var response = [];

                                    _.forEach(data.data.content, function(value, key) {
                                        response.push({
                                            id: value.id,
                                            object: JSOG.decode(value),
                                            value: vm.search.processSearch(JSOG.decode(value))
                                        });
                                    });

                                    response = new SortedArray(response, 'value').sort();

                                    return response;
                                });

                        }
                    }

                    if(!vm.search.searchAdd) {
                        vm.search.searchAdd = function () {
                            if(vm.search.searchedValue && vm.search.searchedValue.id && vm.search.searchedValue.id != -1) {
                                if(vm.search.modalOnAdd) {
                                    var openModal = vm.search.checkOpenModalOnAddFunc ? vm.search.checkOpenModalOnAddFunc(vm.search.searchedValue) : true;

                                    var modal = vm.search.modalOnAdd;
                                    modal.annexaFormly.model = {};
                                    modal.annexaFormly.model.modal_body = vm.search.modalOnAddProcessModel(vm.search.searchedValue);
                                    modal.annexaFormly.options = {};
                                    modal.annexaFormly.options.formState = { readOnly: false, data: vm.content };

                                    if(openModal) {
                                        AnnexaFormlyFactory.showModal('modalSearchAdd', modal, vm.search.completeAdd, false)
                                    } else {
                                        vm.search.completeAdd(modal);
                                    }
                                } else if(vm.search.annexaFormModalOnAdd) {
                                    AnnexaFormlyFactory.showAnnexaFormModal('modalSearchAdd', vm.search.annexaFormModalOnAdd, vm.search.completeAdd);
                                } else {
                                    vm.search.completeAdd(this);
                                }
                            }
                        }
                    }

                    $scope.$on('annexaObjectBoxSearchAdd',function (event) {
                        vm.search.searchAdd();
                    });

                    var newPermissions = [];
                    var viewPermissions = [];

                    if(vm.permissions && vm.permissions.new) {
                         newPermissions = !Array.isArray(vm.permissions.new) ? [vm.permissions.new] : vm.permissions.new;
                    }

                    if(vm.permissions && vm.permissions.view) {
                        viewPermissions = !Array.isArray(vm.permissions.view) ? [vm.permissions.view] : vm.permissions.view;
                    }

                    vm.allPermissions = $linq(newPermissions).union(viewPermissions).toArray();

                }
            }
        }],

        bindings: {
            boxTitle: '@?',
            permissions: '=',
            profiles: '=?',
            search: '=',
            new: '=',
            content: '=',
			newTitle: '=?'
        }
    })
    .component('annexaObjectBoxPagination',{
        templateUrl: './components/common/annexa-object-box/annexa-object-box-pagination.html',
        controller:['$document', function ($document) {
            var vm = this;

            var calculateTotalPages = function () {
                return Math.floor(vm.content.length / vm.itemsPage);
            }

            var scrollToTitle = function() {
                var someElement = angular.element(document.getElementById('box-header-' + vm.anchor));
                $document.scrollToElementAnimated(someElement,200);
            }

            vm.minusPage = function() {
                if(vm.page > 0) {
                    vm.page--;
                    scrollToTitle();
                }
            }

            vm.plusPage = function () {
                if(vm.page < vm.totalPages) {
                    vm.page++;
                    scrollToTitle();
                }
            }

            vm.setPage = function(num) {
                vm.page = num;
                scrollToTitle();
            }

            this.$onInit = function () {
                if(!vm.itemsPage) {
                    vm.itemsPage = 10
                }

                vm.page = 0;
                vm.totalPages = calculateTotalPages();

                vm.collectionPages = [];
                for(var i = 0; i <= vm.totalPages; i++) {
                    vm.collectionPages.push(i);
                }
            }
        }],
        bindings: {
            itemsPage: '=?',
            page: '=',
            anchor: '@',
            content: '='
        }
    })