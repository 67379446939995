angular
    .module('annexaApp')
    .factory('AnnexaBIFactory', ['$http', 'RestService', '$q', '$rootScope', 'GlobalDataFactory', function($http, RestService, $q, $rootScope, GlobalDataFactory) {
        var factory = {};

        factory.BIValues = {};

        factory.loadBIValues = function () {
            if(!factory.promisesInit) {
                $rootScope.loadingDiv = true;
                var promises = [];
                promises.push($http({url: './api/bi/global/0', method: 'GET'}));
                promises.push($http({url: './api/bi/dossier/0', method: 'GET'}));
                promises.push($http({url: './api/bi/dossier/user/0', method: 'GET'}));
                promises.push($http({url: './api/bi/entry/0', method: 'GET'}));

                factory.promisesInit = $q.all(promises).then(function (data) {
                    $rootScope.loadingDiv = false;
                    factory.BIValues = data[0].data;
                    if (!factory.BIValues) {
                        factory.BIValues = data[1].data;
                    }
                    if (data[1].data) {
                        factory.BIValues.dossierDetail = data[1].data.dossierDetail;
                    }
                    if (!factory.BIValues) {
                        factory.BIValues = data[2].data;
                    }
                    if (data[2].data) {
                        factory.BIValues.dossierDetailUser = data[2].data.dossierDetailUser;
                    }
                    if (!factory.BIValues) {
                        factory.BIValues = data[3].data;
                    }
                    if (data[3].data) {
                        factory.BIValues.regInputDetail = data[3].data.regInputDetail;
                        factory.BIValues.regOutputDetail = data[3].data.regOutputDetail;
                    }
                    factory.promisesInit = undefined;
                }).catch(function () {
                    $rootScope.loadingDiv = false;
                    factory.BIValues = {};
                    factory.promisesInit = undefined;
                });
            }
            return factory.promisesInit;
        }

        factory.getBIValues = function (profileId) {
            var deferred = $q.defer();
            $rootScope.loadingDiv = true;
            var promises = [];
            promises.push($http({url: './api/bi/global/' + profileId, method: 'GET'}));
            promises.push($http({url: './api/bi/dossier/' + profileId, method: 'GET'}));
            promises.push($http({url: './api/bi/dossier/user/' + profileId, method: 'GET'}));
            promises.push($http({url: './api/bi/entry/' + profileId, method: 'GET'}));

            factory.promisesInit = $q.all(promises).then(function (data) {
                $rootScope.loadingDiv = false;
                var biValues = data[0].data;
                if (!biValues) {
                    biValues = data[1].data;
                }
                if (data[1].data) {
                    biValues.dossierDetail = data[1].data.dossierDetail;
                }
                if (!biValues) {
                    biValues = data[2].data;
                }
                if (data[2].data) {
                    biValues.dossierDetailUser = data[2].data.dossierDetailUser;
                }
                if (!biValues) {
                    biValues = data[3].data;
                }
                if (data[3].data) {
                    biValues.regInputDetail = data[3].data.regInputDetail;
                    biValues.regOutputDetail = data[3].data.regOutputDetail;
                }
                deferred.resolve(biValues)
            }).catch(function () {
                $rootScope.loadingDiv = false;
                deferred.reject(error);
            });
            return deferred.promise;
        }

        return factory;
    }])
    .factory('AnnexaChartFactory',['$filter', function($filter) {
        var factory = {};

        factory.getTimelineIndexCurrentYear = function (chartValues) {
            var actualYear = new Date().getFullYear() + '-01-01';

            var currentIndex = $linq(chartValues.timelineData).indexOf("x => x == '" + actualYear + "'");

            if(currentIndex == -1) {
                currentIndex = 0;
            }

            return currentIndex;
        }

        factory.getPie4 = function(chartName, greenValue, redValue, purpleValue, pistachioValue) {
            var options =  {
                series: [
                    {
                        type: 'pie',
                        legendHoverLink: false,
                        hoverAnimation: false,
                        avoidLabelOverlap: false,
                        labelLine: { normal: { show: false } },
                        data: [
                            {
                                value: pistachioValue,
                                label: { normal: { show: pistachioValue != 0 ? true : false, position: 'outside', textStyle: { color: '#000000', fontSize: 14 }, formatter: '{c}%' } },
                                labelLine: { normal: { show: pistachioValue != 0 ? true : false } },
                                itemStyle: { normal: { color: '#fcc100' } }

                            },
                            {
                                value: purpleValue,
                                label: { normal: { show: purpleValue != 0 ? true : false, position: 'outside', textStyle: { color: '#000000', fontSize: 14 }, formatter: '{c}%' } },
                                labelLine: { normal: { show: purpleValue != 0 ? true : false } },
                                itemStyle: { normal: { color: '#B6A2DE' } }

                            },
                            {
                                name: redValue,
                                value: redValue,
                                label: { normal: { show: redValue != 0 ? true : false, position: 'outside', textStyle: { color: '#000000', fontSize: 14 }, formatter: '{c}%' } },
                                labelLine: { normal: { show: redValue != 0 ? true : false } },
                                itemStyle: { normal: { color: '#F44455' } }
                            },
                            {
                                value: greenValue,
                                label: { normal: { show: greenValue != 0 ? true : false, position: 'outside', textStyle: { color: '#000000', fontSize: 14 }, formatter: '{c}%' } },
                                labelLine: { normal: { show: greenValue != 0 ? true : false } },
                                itemStyle: { normal: { color: '#71D38F' } }
                            }
                        ]
                    }
                ]
            };

            if(chartName) {
                options.graphic = {
                    elements: [
                        {
                            type: 'text',
                            top: 0,
                            left: 'center',
                            style: {
                                text: chartName
                            }
                        }
                    ]
                }
            }

            return options

        }

        factory.getPie = function(chartName, greenValue, greyValue, greyColor) {
            var options =  {
                series: [
                    {
                        type: 'pie',
                        legendHoverLink: false,
                        hoverAnimation: false,
                        avoidLabelOverlap: false,
                        labelLine: { normal: { show: false } },
                        data: [
                            {
                                name: greyValue,
                                value: greyValue,
                                label: { normal: { show: greyValue != 0 ? true : false, position: 'outside', textStyle: { color: '#000000', fontSize: 14 }, formatter: '{c}%' } },
                                labelLine: { normal: { show: greyValue != 0 ? true : false } },
                                itemStyle: { normal: { color: greyColor ? greyColor : '#F44455' } }
                            },
                            {
                                value: greenValue,
                                label: { normal: { show: greenValue != 0 ? true : false, position: 'outside', textStyle: { color: '#000000', fontSize: 14 }, formatter: '{c}%' } },
                                labelLine: { normal: { show: greenValue != 0 ? true : false } },
                                itemStyle: { normal: { color: '#71D38F' } }
                            }
                        ]
                    }
                ]
            };

            if(chartName) {
                options.graphic = {
                    elements: [
                        {
                            type: 'text',
                            top: 0,
                            left: 'center',
                            style: {
                                text: chartName
                            }
                        }
                    ]
                }
            }

            return options
        }

        factory.getPieDoughnut = function(chartName, greenValue, greyValue, greyColor, labelShow) {
            return {
                series: [
                    {
                        type: 'pie',
                        legendHoverLink: false,
                        hoverAnimation: false,
                        radius: ['50%', '60%'],
                        avoidLabelOverlap: false,
                        labelLine: { normal: { show: false } },
                        data: [
                            {
                                value: greenValue,
                                label: { normal: { show: !labelShow || labelShow == 'green' ? true : false, position: 'center', textStyle: { color: '#000000', fontSize: 18 }, formatter: '{c}%'  } },
                                itemStyle: { normal: { color: '#71D38F' } }
                            },
                            {
                                value: greyValue,
                                label: { normal: { show: labelShow == 'grey' ? true : false, position: 'center', textStyle: { color: '#000000', fontSize: 18 }, formatter: '{c}%'  } },
                                itemStyle: { normal: { color: greyColor ? greyColor : '#eeeeee' } }
                            }
                        ]
                    }
                ],
                graphic: {
                    elements: [
                        {
                            type: 'text',
                            bottom: '10%',
                            left: 'center',
                            style: {
                                text: chartName
                            }
                        }
                    ]
                }
            }
        }

        factory.getBarAnnexaChart = function(chartName, leftCategory, rightCategory, leftValue, rightValue, leftColor, rightColor) {
            return {
                xAxis: {
                    type: 'value',
                    name: chartName,
                    nameLocation: 'middle',
                    nameTextStyle: { fontSize: 14 },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLabel: { show: false },
                    position: 'top',
                    offset: -15
                },
                yAxis: {
                    type: 'category',
                    data: [leftCategory, rightCategory],
                    boundaryGap: false,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLabel: { show: false }
                },
                series: [
                    {
                        type: 'bar',
                        label: { normal: { show: true, formatter: '{c}%' } },
                        itemStyle: { normal: { color: leftColor ? leftColor : '#71D38F', barBorderRadius: [4, 0, 0, 4] } },
                        stack: 'barAnnexaChart',
                        data: [leftValue]
                    },
                    {
                        type: 'bar',
                        label: { normal: { show: true, formatter: '{c}%' } },
                        itemStyle: { normal: { color: rightColor ? rightColor : '#F44455', barBorderRadius: [0, 4, 4, 0] } },
                        stack: 'barAnnexaChart',
                        data: [rightValue]
                    }
                ]
            }
        }

        factory.get1TimelineChart = function(iterateProperty, valueProperty, legend, values, valueMultiplier) {
            if(legend.length == 1) {
                var chartValues = factory.get1TimelineValues(iterateProperty, valueProperty, values, valueMultiplier);

                return {
                    baseOption: {
                        timeline: {
                            currentIndex: factory.getTimelineIndexCurrentYear(chartValues),
                            data: chartValues.timelineData,
                            controlStyle: {showPlayBtn: false},
                            label: {
                                normal: {
                                    position: 'left',
                                    formatter: function (value, index) {
                                        return new Date(value).getFullYear();
                                    }
                                }
                            },
                            padding: -10,
                            bottom: 10
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: legend
                        },
                        calculable: true,
                        xAxis: [
                            {
                                type: 'category',
                                data: ['Gen', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dec'],
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {lineStyle: {color: '#000000'}},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {show: false},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        series: [
                            {
                                name: legend[0],
                                type: 'bar',
                                itemStyle: { normal: { color: '#71D38F', barBorderRadius: [0, 0, 4, 4] } },
                                markPoint: {
                                    data: [
                                        { type: 'max', name: 'maxium' },
                                        { type: 'min', name: 'minium' }
                                    ]
                                },
                                markLine: {
                                    data: [
                                        { type: 'average', name: 'average' }
                                    ]
                                }
                            }
                        ]
                    },
                    options: chartValues.optionsData
                }
            } else {
                return undefined;
            }
        }

        factory.get2TimelineChart = function (iterateProperty, valueProperty1, valueProperty2, legend, values) {
            if(legend.length == 2) {
                var chartValues = factory.get2TimelineValues(iterateProperty, valueProperty1, valueProperty2, values);

                return {
                    baseOption: {
                        timeline: {
                            currentIndex: factory.getTimelineIndexCurrentYear(chartValues),
                            data: chartValues.timelineData,
                            controlStyle: {showPlayBtn: false},
                            label: {
                                normal: {
                                    position: 'left',
                                    formatter: function (value, index) {
                                        return new Date(value).getFullYear();
                                    }
                                }
                            },
                            padding: -10,
                            bottom: 10
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: legend
                        },
                        calculable: true,
                        xAxis: [
                            {
                                type: 'category',
                                data: ['Gen', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dec'],
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {lineStyle: {color: '#000000'}},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {show: false},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        series: [
                            {
                                name: legend[0],
                                type: 'bar',
                                itemStyle: { normal: { color: '#71D38F', barBorderRadius: [0, 0, 4, 4] } },
                                markPoint: {
                                    data: [
                                        { type: 'max', name: 'maxium' },
                                        { type: 'min', name: 'minium' }
                                    ]
                                },
                                markLine: {
                                    data: [
                                        { type: 'average', name: 'average' }
                                    ]
                                }
                            },
                            {
                                name: legend[1],
                                type: 'bar',
                                stack: legend[0],
                                itemStyle: { normal: { color: '#F44455', barBorderRadius: [4, 4, 0, 0] } }
                            }
                        ]
                    },
                    options: chartValues.optionsData
                }
            } else {
                return undefined;
            }
        }

        factory.get2TimelineStackedChart = function (iterateProperty, valueProperty1, valueProperty2, legend, values, averageSuffix) {
            if(legend.length == 2) {
                var chartValues = factory.get2TimelineValues(iterateProperty, valueProperty1, valueProperty2, values);
                return {
                    baseOption: {
                        timeline: {
                            currentIndex: factory.getTimelineIndexCurrentYear(chartValues),
                            data: chartValues.timelineData,
                            controlStyle: {showPlayBtn: false},
                            label: {
                                normal: {
                                    position: 'left',
                                    formatter: function (value, index) {
                                        return new Date(value).getFullYear();
                                    }
                                }
                            },
                            padding: -10,
                            bottom: 10
                        },
                        tooltip: {
                            trigger: 'axis',
                            formatter: '{b0}<br/><br/>{a0}: {c0}<br/>{a1}: {c1}'
                        },
                        legend: {
                            data: legend
                        },
                        calculable: true,
                        xAxis: [
                            {
                                type: 'category',
                                data: ['Gen', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dec'],
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {lineStyle: {color: '#000000'}},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {show: false},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        series: [
                            {
                                name: legend[0],
                                type: 'bar',
                                label: { normal: { show: true } },
                                itemStyle: { normal: { color: '#71D38F', barBorderRadius: [0, 0, 4, 4] } },
                                stack: 'barStacked',
                                markLine: {
                                    data: [
                                        { type: 'average', name: 'average', label: { normal: { show: true, formatter: $filter('translate')('global.literals.average') + ': {c} ' + (averageSuffix ? $filter('translate')(averageSuffix) : '') } } }
                                    ]
                                }
                            },
                            {
                                name: legend[1],
                                type: 'bar',
                                stack: 'barStacked',
                                label: { normal: { show: true } },
                                itemStyle: { normal: { color: '#F44455', barBorderRadius: [4, 4, 0, 0] } }
                            },
                            {
                                name: 'Total',
                                type: 'bar',
                                stack: 'barStacked',
                                itemStyle: { normal: { color: '#000000' } }
                            }
                        ]
                    },
                    options: chartValues.optionsData
                }
            } else {
                return undefined;
            }
        }

        factory.get4TimelineChart = function (iterateProperty, valueProperty1, valueProperty2, valueProperty3, valueProperty4, legend, values) {
            if(legend.length == 4) {
                var chartValues = factory.get4TimelineValues(iterateProperty, valueProperty1, valueProperty2, valueProperty3, valueProperty4, values);

                return {
                    baseOption: {
                        timeline: {
                            currentIndex: factory.getTimelineIndexCurrentYear(chartValues),
                            data: chartValues.timelineData,
                            controlStyle: {showPlayBtn: false},
                            label: {
                                normal: {
                                    position: 'left',
                                    formatter: function (value, index) {
                                        return new Date(value).getFullYear();
                                    }
                                }
                            },
                            padding: -10,
                            bottom: 10
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: legend
                        },
                        calculable: true,
                        xAxis: [
                            {
                                type: 'category',
                                data: ['Gen', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dec'],
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {lineStyle: {color: '#000000'}},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                                axisTick: {show: false},
                                axisLabel: {textStyle: {color: '#D1D1D1'}}
                            }
                        ],
                        series: [
                            {
                                name: legend[0],
                                type: 'bar',
                                stack: legend[0],
                                itemStyle: { normal: { color: '#71D38F', barBorderRadius: [0, 0, 4, 4] } },
                                markPoint: {
                                    data: [
                                        { type: 'max', name: 'maxium' },
                                        { type: 'min', name: 'minium' }
                                    ]
                                },
                                markLine: {
                                    data: [
                                        { type: 'average', name: 'average' }
                                    ]
                                }
                            },
                            {
                                name: legend[1],
                                type: 'bar',
                                stack: legend[0],
                                itemStyle: { normal: { color: '#F44455', barBorderRadius: [4, 4, 0, 0] } }
                            },
                            {
                                name: legend[2],
                                type: 'bar',
                                stack: legend[2],
                                itemStyle: { normal: { color: '#B6A2DE', barBorderRadius: [0, 0, 4, 4] } },
                                markPoint: {
                                    data: [
                                        { type: 'max', name: 'maxium' },
                                        { type: 'min', name: 'minium' }
                                    ]
                                },
                                markLine: {
                                    data: [
                                        { type: 'average', name: 'average' }
                                    ]
                                }
                            },
                            {
                                name: legend[3],
                                type: 'bar',
                                stack: legend[2],
                                itemStyle: { normal: { color: '#fcc100', barBorderRadius: [4, 4, 0, 0] } }
                            }
                        ]
                    },
                    options: chartValues.optionsData
                };
            } else {
                return undefined;
            }
        }

        factory.get1TimelineValues = function (iterateProperty, valueProperty, dataValues, valueMultiplier) {
            var timelineData = [];
            var optionsData = [];

            var actualYear = undefined;
            var values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            _.forEach(dataValues[iterateProperty], function(value) {
                var date = new Date(value.date);

                if(actualYear != date.getFullYear()) {
                    timelineData.push(date.getFullYear() + '-01-01');

                    if(actualYear) {
                        optionsData.push(
                            {
                                series: [
                                    { data: values }
                                ]
                            }
                        )
                    }

                    actualYear = date.getFullYear();
                    values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                }

                if(valueMultiplier) {
                    values[date.getMonth()] = value[valueProperty] * valueMultiplier;
                } else {
                    values[date.getMonth()] = value[valueProperty];
                }
            });

            if(actualYear) {
                optionsData.push(
                    {
                        series: [
                            { data: values }
                        ]
                    }
                )
            }

            return { timelineData: timelineData, optionsData: optionsData };
        }

        factory.get2TimelineValues = function (iterateProperty, valueProperty1, valueProperty2, values) {
            var getData = function(value, isTotal) {
                if(isTotal) {
                    return {
                        value: 0,
                        label:
                            {
                                normal:
                                    {
                                        show: value != 0 ? true : false,
                                        position: 'top',
                                        distance: 15,
                                        formatter: '' + value,
                                        backgroundColor: '#eee',
                                        borderColor: 'rgb(199,86,83)',
                                        borderWidth: 2,
                                        borderRadius: 5,
                                        padding: 10,
                                        color: '#000',
                                        fontSize: 12,
                                        shadowBlur: 3,
                                        shadowColor: '#888',
                                        shadowOffsetX: 0,
                                        shadowOffsetY: 3,
                                        lineHeight: 30
                                    }
                            }
                    }
                } else {
                    return {
                        value: value,
                        label: {normal: {show: value != 0 ? true : false}}
                    }
                }
            }

            var timelineData = [];
            var optionsData = [];

            var actualYear = undefined;
            var values1 = [getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0)];
            var values2 = [getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0)];
            var values3 = [getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true)];

            _.forEach(values[iterateProperty], function(value) {
                var date = new Date(value.date);

                if(actualYear != date.getFullYear()) {
                    timelineData.push(date.getFullYear() + '-01-01');

                    if(actualYear) {
                        optionsData.push(
                            {
                                series: [
                                    { data: values1 },
                                    { data: values2 },
                                    { data: values3 }
                                ]
                            }
                        )
                    }

                    actualYear = date.getFullYear();
                    values1 = [getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0)];
                    values2 = [getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0), getData(0)];
                    values3 = [getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true), getData(0, true)];
                }

                values1[date.getMonth()] = getData(value[valueProperty1]);
                values2[date.getMonth()] = getData(value[valueProperty2]);
                values3[date.getMonth()] = getData(value[valueProperty1] + value[valueProperty2], true);
            });

            if(actualYear) {
                optionsData.push(
                    {
                        series: [
                            { data: values1 },
                            { data: values2 },
                            { data: values3 }
                        ]
                    }
                )
            }

            return { timelineData: timelineData, optionsData: optionsData };
        }

        factory.get4TimelineValues = function (iterateProperty, valueProperty1, valueProperty2, valueProperty3, valueProperty4, values) {
            var timelineData = [];
            var optionsData = [];

            var actualYear = undefined;
            var values1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var values2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var values3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var values4 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            _.forEach(values[iterateProperty], function(value) {
                var date = new Date(value.date);

                if(actualYear != date.getFullYear()) {
                    timelineData.push(date.getFullYear() + '-01-01');

                    if(actualYear) {
                        optionsData.push(
                            {
                                series: [
                                    { data: values1 },
                                    { data: values2 },
                                    { data: values3 },
                                    { data: values4 }
                                ]
                            }
                        )
                    }

                    actualYear = date.getFullYear();
                    values1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    values2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    values3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    values4 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                }

                values1[date.getMonth()] = value[valueProperty1];
                values2[date.getMonth()] = value[valueProperty2];
                values3[date.getMonth()] = value[valueProperty3];
                values4[date.getMonth()] = value[valueProperty4];
            });

            if(actualYear) {
                optionsData.push(
                    {
                        series: [
                            { data: values1 },
                            { data: values2 },
                            { data: values3 },
                            { data: values4 }
                        ]
                    }
                )
            }

            return { timelineData: timelineData, optionsData: optionsData };
        }

        return factory;
    }])
    .component('annexaChart',{
        templateUrl: './components/common/annexa-chart/annexa-chart.html',
        controller:['$element', 'AnnexaChartFactory', '$filter', '$rootScope', '$scope', function($element, AnnexaChartFactory, $filter, $rootScope, $scope) {
            var vm = this;

            vm.containerStyle = {
                "width": "100%",
                "min-height": vm.height + "px"
            };

            var setChartOption = function(type, options) {
                switch (type) {
                    case 'bar':
                        var totalValue = Number(options.leftValue) + Number(options.rightValue);

                        vm.chart.setOption(AnnexaChartFactory.getBarAnnexaChart(
                            $filter('translate')(options.chartName) + (options.showTotal ? ' (' + totalValue + ')' : ''),
                            $filter('translate')(options.leftCategory),
                            $filter('translate')(options.rightCategory),
                            Math.round((options.leftValue * 100) / totalValue),
                            Math.round((options.rightValue * 100) / totalValue),
                            options.leftColor,
                            options.rightColor
                        ));
                        break;
                    case 'pieDoughnut':
                        var totalValue = Number(options.greenValue) + Number(options.greyValue);
                        options.totalValue = totalValue;

                        vm.chart.setOption(AnnexaChartFactory.getPieDoughnut(
                            $filter('translate')(options.chartName),
                            Math.round((options.greenValue * 100) / totalValue),
                            Math.round((options.greyValue * 100) / totalValue),
                            options.greyColor,
                            options.labelShow
                        ));
                        break;
                    case 'pie':
                        var totalValue = Number(options.greenValue) + Number(options.greyValue);
                        vm.options.totalValue = totalValue;

                        vm.chart.setOption(AnnexaChartFactory.getPie(
                            $filter('translate')(options.chartName),
                            Math.round((options.greenValue * 100) / totalValue),
                            Math.round((options.greyValue * 100) / totalValue),
                            options.greyColor
                        ));
                        break;
                    case 'pie4':
                        var totalValue = Number(options.greenValue) + Number(options.redValue) + Number(options.purpleValue) + Number(options.pistachioValue);
                        vm.options.totalValue = totalValue;

                        vm.chart.setOption(AnnexaChartFactory.getPie4(
                            $filter('translate')(options.chartName),
                            Math.round((options.greenValue * 100) / totalValue),
                            Math.round((options.redValue * 100) / totalValue),
                            Math.round((options.purpleValue * 100) / totalValue),
                            Math.round((options.pistachioValue * 100) / totalValue)
                        ))

                    default:
                        vm.chart.setOption(options);
                        break;
                }

            }

            this.$onChanges = function (changes) {
                if(changes.options && !changes.containerId) {
                    setChartOption(vm.type, changes.options.currentValue);
                }
            }

            $scope.$on('annexaChartRefresh', function (event, args) {
                if(args.containerId == vm.containerId) {
                    setChartOption(vm.type, vm.options);
                }
            });

            this.$onInit = function () {
                if(vm.options) {
                    vm.chart = echarts.init($element[0].children[0]);

                    setChartOption(vm.type, vm.options);

                    vm.chart.on('timelinechanged', function (params) {
                        $rootScope.$broadcast('annexaChartTimelineChanged', {
                            containerId: vm.containerId,
                            currentIndex: params.currentIndex
                        });
                    });

                    vm.chart.on('legendselectchanged', function(params) {
                        if(vm.checkLegendChange == 'true') {
                            var selected = [];

                            _.forOwn(params.selected, function(sel) {
                                selected.push(sel);
                            });

                            _.forEach(vm.options.options, function(opt, optIndex) {
                                if(opt.series[0].data.length == 12 && opt.series[1].data.length == 12 && opt.series[2].data.length == 12) {
                                    for(var i = 0; i < 12; i++) {
                                        var total = (selected[0] ? opt.series[0].data[i].value : 0) + (selected[1] ? opt.series[1].data[i].value : 0);

                                        vm.options.options[optIndex].series[2].data[i].label.normal.formatter = '' + total;
                                    }
                                }
                            });

                            setChartOption(vm.type, vm.options);
                        }
                    });

                    vm.chart.resize({height: vm.height});
                }
            }
        }],
        bindings: {
            containerId: '@',
            containerClasses: '@?',
            labelClasses: '@?',
            type: '@',
            options: '<',
            height: '@',
            checkLegendChange: '@'
        }
    })
    .component('annexaChartDossiers',{
        templateUrl: './components/common/annexa-chart/annexa-chart-dossiers.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller: ['AnnexaBIFactory', '$filter', '$scope', '$rootScope', 'GlobalDataFactory', function(AnnexaBIFactory, $filter, $scope, $rootScope, GlobalDataFactory) {
            var vm = this;

            var oOK = 'openDossiersOK';
            var oKO = 'openDossiersKO';
            var cOK = 'closedDossiersOK';
            var cKO = 'closedDossiersKO';

            if(vm.isUser) {
                oOK += 'User';
                oKO += 'User';
                cOK += 'User';
                cKO += 'User';
            }

            $scope.$on('annexaBoxChartFilter', function (event, args) {
                if(args.filterId == 'chartDossiers') {
                    AnnexaBIFactory.getBIValues(args.item.id)
                        .then(function (data) {
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    vm.chartOpenDossiers = vm.annexaBoxChartComponent.assignLeftRightValues(vm.chartOpenDossiers, oOK, oKO, data)
                                    vm.chartClosedDossiers = vm.annexaBoxChartComponent.assignLeftRightValues(vm.chartClosedDossiers, cOK, cKO, data)

                                    vm.annexaBoxChartComponent.refreshChart('chartOpenDossiers');
                                    vm.annexaBoxChartComponent.refreshChart('chartClosedDossiers');
                                })
                            })
                        }).catch(function (error) {
                            //Empty
                    });
                }
            })



            vm.chartOpenDossiers = {
                chartName: 'global.literals.openedM',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues[oOK],
                rightValue: AnnexaBIFactory.BIValues[oKO],
                showTotal: true
            }

            vm.chartClosedDossiers = {
                chartName: 'global.literals.closedM',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues[cOK],
                rightValue: AnnexaBIFactory.BIValues[cKO],
                showTotal: true
            }

            this.$onInit = function () {
                vm.annexaBoxChartComponent.filterOptions = [
                    {
                        id: 0,
                        language1: $filter('translate')('global.literals.allProfiles'),
                        language2: $filter('translate')('global.literals.allProfiles'),
                        language3: $filter('translate')('global.literals.allProfiles')
                    }
                ];

                var profiles = GlobalDataFactory.allProfiles;

                if(vm.isUser) {
                    profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                }

                vm.annexaBoxChartComponent.filterOptions = vm.annexaBoxChartComponent.filterOptions.concat(profiles);
            }
        }],
        bindings: {
            isUser: '=?'
        }
    })
    .component('annexaChartTasks', {
        templateUrl: './components/common/annexa-chart/annexa-chart-tasks.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', function (AnnexaBIFactory) {
            var vm = this;

            vm.chartGlobalOpenTasks = {
                chartName: 'global.literals.openedF',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues.openGlobalTasksOK,
                rightValue: AnnexaBIFactory.BIValues.openGlobalTasksKO,
                showTotal: true
            }

            vm.chartGlobalClosedTasks = {
                chartName: 'global.literals.closedF',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues.closedGlobalTasksOK,
                rightValue: AnnexaBIFactory.BIValues.closedGlobalTasksKO,
                showTotal: true
            };
        }]
    })
    .component('annexaChartTasksUsers', {
        templateUrl: './components/common/annexa-chart/annexa-chart-tasks-users.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', function (AnnexaBIFactory) {
            var vm = this;

            vm.chartForUserOpenTasks = {
                chartName: 'global.literals.openedF',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues.openCreatedTasksOKForUser,
                rightValue: AnnexaBIFactory.BIValues.openCreatedTasksKOForUser,
                showTotal: true
            }

            vm.chartForUserClosedTasks = {
                chartName: 'global.literals.closedF',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues.closedCreatedTasksOKForUser,
                rightValue: AnnexaBIFactory.BIValues.closedCreatedTasksKOForUser,
                showTotal: true
            };

            vm.chartByUserOpenTasks = {
                chartName: 'global.literals.openedF',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues.openCreatedTasksOKByUser,
                rightValue: AnnexaBIFactory.BIValues.openCreatedTasksKOByUser,
                showTotal: true
            }

            vm.chartByUserClosedTasks = {
                chartName: 'global.literals.closedF',
                leftCategory: 'global.literals.withinDeadline',
                rightCategory: 'global.literals.outOfTime',
                leftValue: AnnexaBIFactory.BIValues.closedCreatedTasksOKByUser,
                rightValue: AnnexaBIFactory.BIValues.closedCreatedTasksKOByUser,
                showTotal: true
            };
        }]
    })
    .component('annexaChartAlerts', {
        templateUrl: './components/common/annexa-chart/annexa-chart-alerts.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', function (AnnexaBIFactory) {
            var vm = this;

            var readed = 'globalAlertsReaded';
            var noReaded = 'globalAlertsNoReaded';

            if(vm.isUser) {
                readed = 'alertsReaded';
                noReaded = 'alertsNoReaded';
            }

            vm.chartGlobalAlerts = {
                chartName: 'global.literals.noReaded',
                greenValue: AnnexaBIFactory.BIValues[readed],
                greyValue: AnnexaBIFactory.BIValues[noReaded],
                greyColor: '#F44455',
                greenLabel: 'global.literals.alertsReaded',
                greyLabel: 'global.literals.alertsNoReaded',
                totalLabel: 'global.literals.alertsTotal',
                labelShow: 'grey'
            };
        }],
        bindings: {
            isUser: '=?'
        }
    })
    .component('annexaChartRegInput', {
        templateUrl: './components/common/annexa-chart/annexa-chart-reg-input.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', function (AnnexaBIFactory) {
            var vm = this;

            vm.chartRegInput = {
                greenValue: AnnexaBIFactory.BIValues.regInputTelematic,
                greyValue: AnnexaBIFactory.BIValues.regInputNoTelematic,
                greenLabel: 'global.literals.telematics',
                greyLabel: 'global.literals.noTelematics',
                greyColor: '#00a3cc'
            }

            vm.chartRegInputDiligencesTramitation = {
                chartName: 'global.literals.tramitation',
                greenValue: AnnexaBIFactory.BIValues.tramitationDiligencesFinished,
                redValue: AnnexaBIFactory.BIValues.tramitationDiligencesPendingAccept,
                purpleValue: AnnexaBIFactory.BIValues.tramitationDiligencesAccept,
                pistachioValue: AnnexaBIFactory.BIValues.tramitationDiligencesRefused,
                greenLabel: 'global.literals.closedF',
                redLabel: 'global.literals.pending',
                purpleLabel: 'global.literals.accepted',
                pistachioLabel: 'global.literals.refused'
            }

            vm.chartRegInputDiligencesKnowledge = {
                chartName: 'global.literals.knowledge',
                greenValue: AnnexaBIFactory.BIValues.knowledgeDiligencesReaded,
                greyValue: AnnexaBIFactory.BIValues.knowledgeDiligencesPendingRead,
                greenLabel: 'global.literals.readedF',
                greyLabel: 'global.literals.noReadedF'
            }
        }]
    })
    .component('annexaChartRegOutput', {
        templateUrl: './components/common/annexa-chart/annexa-chart-reg-output.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller: ['AnnexaBIFactory', function (AnnexaBIFactory) {
            var vm = this;

            vm.chartRegOutpuChanel = {
                chartName: 'global.literals.channel',
                leftCategory: 'global.literals.telematics',
                rightCategory: 'global.literals.noTelematics',
                leftValue: AnnexaBIFactory.BIValues.regOutputTelematic,
                rightValue: AnnexaBIFactory.BIValues.regOutputNoTelematic,
                rightColor: '#00a3cc',
                showTotal: true
            }
        }]
    })
    .component('annexaChartSavings', {
        templateUrl: './components/common/annexa-chart/annexa-chart-savings.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', '$rootScope', function (AnnexaBIFactory, $rootScope) {
            var vm = this;

            vm.chartCurrentSavings = {
                chartName: 'global.literals.notifications',
                leftCategory: 'global.literals.telematicF',
                rightCategory: 'global.literals.postals',
                leftValue: AnnexaBIFactory.BIValues.noPostal,
                rightValue: AnnexaBIFactory.BIValues.postal,
                showTotal: true
            }

            vm.chartCurrentSavingsTotal = (AnnexaBIFactory.BIValues.noPostal + AnnexaBIFactory.BIValues.postal) * $rootScope.app.configuration.bi_savings_price.value + ' €';
            vm.chartCurrentSavingsSaved = AnnexaBIFactory.BIValues.noPostal * $rootScope.app.configuration.bi_savings_price.value + ' €';
        }]
    })
    .component('annexaChartSavingsDetail',{
        templateUrl: './components/common/annexa-chart/annexa-chart-savings-detail.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', 'AnnexaChartFactory', '$scope', '$rootScope', '$filter', function (AnnexaBIFactory, AnnexaChartFactory, $scope, $rootScope, $filter) {
            var vm = this;

            var currentIndex = 0;

            var initializeCharts = function(values) {
                vm.chartSavingsDetail = AnnexaChartFactory.get2TimelineStackedChart('savingsDetail', 'value2', 'value1', [$filter('translate')('global.literals.telematicF'), $filter('translate')('global.literals.postals')], values);
                currentIndex = vm.chartSavingsDetail.baseOption.timeline.currentIndex;

                vm.chartSavingsDetail.baseOption.yAxis.push({
                    type: 'value',
                    position: 'right',
                    axisLine: {lineStyle: {color: '#008ACD', width: 2}},
                    axisTick: {show: false},
                    axisLabel: {textStyle: {color: '#D1D1D1'}, formatter: '{value} €'}
                });

                vm.chartSavingsDetail.baseOption.tooltip.formatter = '{b0}<br/><br/>{a0}: {c0}<br/>{a1}: {c1}<br/><br/>Estalvi: {c3} €'

                _.forEach(vm.chartSavingsDetail.options, function(opt, index) {
                    var lineData = [];

                    _.forEach(opt.series[2].data, function(value) {
                        lineData.push(Number(value.label.normal.formatter) * $rootScope.app.configuration.bi_savings_price.value);
                    });

                    vm.chartSavingsDetail.options[index].series.push({
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: { normal: { color: '#cccc00' } },
                        data: lineData
                    });
                });


                var yearSelected = undefined;
                if(vm.chartSavingsDetail && vm.chartSavingsDetail.baseOption && vm.chartSavingsDetail.baseOption.timeline && vm.chartSavingsDetail.baseOption.timeline.data && vm.chartSavingsDetail.baseOption.timeline.data[currentIndex]){
                    yearSelected = vm.chartSavingsDetail.baseOption.timeline.data[currentIndex].substring(0,4);
                }

                if(vm.chartSavingsDetail && vm.chartSavingsDetail.options && vm.chartSavingsDetail.options[currentIndex] && vm.chartSavingsDetail.options[currentIndex].series && vm.chartSavingsDetail.options[currentIndex].series[0] && vm.chartSavingsDetail.options[currentIndex].series[0].data && vm.chartSavingsDetail.options[currentIndex].series[1] && vm.chartSavingsDetail.options[currentIndex].series[1].data){
                    vm.chartCurrentSavingsTotal = {
                        chartName: 'global.literals.notifications',
                        leftCategory: 'global.literals.telematicF',
                        rightCategory: 'global.literals.postals',
                        leftValue: $linq(vm.chartSavingsDetail.options[currentIndex].series[0].data).sum("x => x.value"),
                        rightValue: $linq(vm.chartSavingsDetail.options[currentIndex].series[1].data).sum("x => x.value"),
                        showTotal: true
                    }

                    var total = ($linq(vm.chartSavingsDetail.options[currentIndex].series[0].data).sum("x => x.value") + $linq(vm.chartSavingsDetail.options[currentIndex].series[1].data).sum("x => x.value")) * $rootScope.app.configuration.bi_savings_price.value;

                    vm.chartCurrentSavingsTotalGlobal = total + ' €';
                    vm.chartCurrentSavingsSavedGlobal = $linq(vm.chartSavingsDetail.options[currentIndex].series[0].data).sum("x => x.value") * $rootScope.app.configuration.bi_savings_price.value + ' €';
                }else{
                    vm.chartCurrentSavingsTotal = {
                        chartName: 'global.literals.notifications',
                        leftCategory: 'global.literals.telematicF',
                        rightCategory: 'global.literals.postals',
                        leftValue: 0,
                        rightValue: 0,
                        showTotal: true
                    }
                    vm.chartCurrentSavingsTotalGlobal = '0 €';
                    vm.chartCurrentSavingsSavedGlobal = '0 €';
                }

            }

            initializeCharts(AnnexaBIFactory.BIValues);

            $scope.$on('annexaChartTimelineChanged', function(event, args) {
                if(args.containerId == 'chartSavingsDetail') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartCurrentSavingsTotal = {
                                chartName: 'global.literals.notifications',
                                leftCategory: 'global.literals.telematicF',
                                rightCategory: 'global.literals.postals',
                                leftValue: $linq(vm.chartSavingsDetail.options[currentIndex].series[0].data).sum("x => x.value"),
                                rightValue: $linq(vm.chartSavingsDetail.options[currentIndex].series[1].data).sum("x => x.value"),
                            }

                            vm.annexaBoxChartComponent.refreshChart('chartCurrentSavingsTotal');

                            var total = ($linq(vm.chartSavingsDetail.options[currentIndex].series[0].data).sum("x => x.value") + $linq(vm.chartSavingsDetail.options[currentIndex].series[1].data).sum("x => x.value")) * $rootScope.app.configuration.bi_savings_price.value;

                            vm.chartCurrentSavingsTotalGlobal = total + ' €';
                            vm.chartCurrentSavingsSavedGlobal = $linq(vm.chartSavingsDetail.options[currentIndex].series[0].data).sum("x => x.value") * $rootScope.app.configuration.bi_savings_price.value + ' €';
                        })
                    })
                }
            });
        }]
    })
    .component('annexaChartDossiersDetail',{
        templateUrl: './components/common/annexa-chart/annexa-chart-dossiers-detail.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', 'AnnexaChartFactory', '$scope', '$filter', '$rootScope', 'GlobalDataFactory', function(AnnexaBIFactory, AnnexaChartFactory, $scope, $filter, $rootScope, GlobalDataFactory) {
            var vm = this;

            var currentIndex = 0;

            var detailsList = 'dossierDetail';

            if(vm.isUser) {
                detailsList = 'dossierDetailUser';
            }


            var initizlizeCharts = function(values) {
                vm.chartDossiersDetailOpen = AnnexaChartFactory.get2TimelineStackedChart(detailsList, 'openOK', 'openKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.dossiersInTime');
                vm.chartDossiersDetailClosed = AnnexaChartFactory.get2TimelineStackedChart(detailsList, 'closedOK', 'closedKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.dossiersInTime');

                currentIndex = vm.chartDossiersDetailOpen.baseOption.timeline.currentIndex;

                vm.chartOpenDossiersDetail = {
                    chartName: 'global.literals.openedM',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartDossiersDetailOpen.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartDossiersDetailOpen.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }

                vm.chartClosedDossiersDetail = {
                    chartName: 'global.literals.closedM',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartDossiersDetailClosed.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartDossiersDetailClosed.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }
            }

            initizlizeCharts(AnnexaBIFactory.BIValues);

            this.$onInit = function () {
                vm.annexaBoxChartComponent.filterOptions = [
                    {
                        id: 0,
                        language1: $filter('translate')('global.literals.allProfiles'),
                        language2: $filter('translate')('global.literals.allProfiles'),
                        language3: $filter('translate')('global.literals.allProfiles')
                    }
                ];

                var profiles = GlobalDataFactory.allProfiles;

                if(vm.isUser) {
                    profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                }

                vm.annexaBoxChartComponent.filterOptions = vm.annexaBoxChartComponent.filterOptions.concat(profiles);
            }

            $scope.$on('annexaBoxChartFilter', function (event, args) {
                if(args.filterId == 'chartDossiersDetail') {
                    AnnexaBIFactory.getBIValues(args.item.id)
                        .then(function (data) {
                            initizlizeCharts(data);
                        }).catch(function (error) {
                        //Empty
                    });
                }
            })

            $scope.$on('annexaChartTimelineChanged', function(event, args) {
                if(args.containerId == 'chartDossiersDetailOpen') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartOpenDossiersDetail = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartOpenDossiersDetail, vm.chartDossiersDetailOpen.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartOpenDossiersDetail');
                        })
                    })
                } else if (args.containerId == 'chartDossiersDetailClosed') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartClosedDossiersDetail = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartClosedDossiersDetail, vm.chartDossiersDetailClosed.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartClosedDossiersDetail');
                        })
                    })
                }
            });
        }],
        bindings: {
            isUser: '=?'
        }
    })
    .component('annexaChartTasksDetailUser',{
        templateUrl: './components/common/annexa-chart/annexa-chart-tasks-detail-user.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', 'AnnexaChartFactory', '$scope', '$filter', function(AnnexaBIFactory, AnnexaChartFactory, $scope, $filter) {
            var vm = this;

            var currentIndex = 0;

            var initizlizeCharts = function(values) {
                vm.chartTaksDetailOpenForUser = AnnexaChartFactory.get2TimelineStackedChart('taskForUserDetail', 'openOK', 'openKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.tasksInTime');
                vm.chartTaksDetailClosedForUser = AnnexaChartFactory.get2TimelineStackedChart('taskForUserDetail', 'closedOK', 'closedKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.tasksInTime');
                vm.chartTaksDetailOpenByUser = AnnexaChartFactory.get2TimelineStackedChart('taskByUserDetail', 'openOK', 'openKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.tasksInTime');
                vm.chartTaksDetailClosedByUser = AnnexaChartFactory.get2TimelineStackedChart('taskByUserDetail', 'closedOK', 'closedKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.tasksInTime');

                currentIndex = vm.chartTaksDetailOpenForUser.baseOption.timeline.currentIndex;

                vm.chartOpenTasksDetailForUser = {
                    chartName: 'global.literals.openedF',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartTaksDetailOpenForUser.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartTaksDetailOpenForUser.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }

                vm.chartClosedTasksDetailForUser = {
                    chartName: 'global.literals.closedF',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartTaksDetailClosedForUser.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartTaksDetailClosedForUser.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }

                vm.chartOpenTasksDetailByUser = {
                    chartName: 'global.literals.openedF',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartTaksDetailOpenByUser.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartTaksDetailOpenByUser.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }

                vm.chartClosedTasksDetailByUser = {
                    chartName: 'global.literals.closedF',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartTaksDetailClosedByUser.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartTaksDetailClosedByUser.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }
            }

            initizlizeCharts(AnnexaBIFactory.BIValues);

            $scope.$on('annexaChartTimelineChanged', function(event, args) {
                if(args.containerId == 'chartTaksDetailOpenForUser') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartOpenTasksDetailForUser = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartOpenTasksDetailForUser, vm.chartOpenTasksDetailForUser.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartOpenTasksDetailForUser');
                        })
                    })
                } else if (args.containerId == 'chartTaksDetailClosedForUser') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartClosedTasksDetailForUser = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartClosedTasksDetailForUser, vm.chartClosedTasksDetailForUser.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartClosedTasksDetailForUser');
                        })
                    })
                } else if (args.containerId == 'chartTaksDetailOpenByUser') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartOpenTasksDetailByUser = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartOpenTasksDetailByUser, vm.chartTaksDetailOpenByUser.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartTaksDetailOpenByUser');
                        })
                    })
                } else if (args.containerId == 'chartTaksDetailClosedByUser') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartClosedTasksDetailByUser = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartClosedTasksDetailByUser, vm.chartTaksDetailClosedByUser.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartTaksDetailClosedByUser');
                        })
                    })
                }
            });
        }]
    })
    .component('annexaChartTasksDetail',{
        templateUrl: './components/common/annexa-chart/annexa-chart-tasks-detail.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', 'AnnexaChartFactory', '$scope', '$filter', function(AnnexaBIFactory, AnnexaChartFactory, $scope, $filter) {
            var vm = this;

            var currentIndex = 0;

            var initizlizeCharts = function(values) {
                vm.chartTaksDetailOpen = AnnexaChartFactory.get2TimelineStackedChart('taskDetail', 'openOK', 'openKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.tasksInTime');
                vm.chartTaksDetailClosed = AnnexaChartFactory.get2TimelineStackedChart('taskDetail', 'closedOK', 'closedKO', [$filter('translate')('global.literals.withinDeadline'), $filter('translate')('global.literals.outOfTime')], values, 'global.literals.tasksInTime');

                currentIndex = vm.chartTaksDetailOpen.baseOption.timeline.currentIndex;

                vm.chartOpenTasksDetail = {
                    chartName: 'global.literals.openedF',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartTaksDetailOpen.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartTaksDetailOpen.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }

                vm.chartClosedTasksDetail = {
                    chartName: 'global.literals.closedF',
                    leftCategory: 'global.literals.withinDeadline',
                    rightCategory: 'global.literals.outOfTime',
                    leftValue: $linq(vm.chartTaksDetailClosed.options[currentIndex].series[0].data).sum("x => x.value"),
                    rightValue: $linq(vm.chartTaksDetailClosed.options[currentIndex].series[1].data).sum("x => x.value"),
                    showTotal: true
                }
            }

            initizlizeCharts(AnnexaBIFactory.BIValues);

            $scope.$on('annexaChartTimelineChanged', function(event, args) {
                if(args.containerId == 'chartTaksDetailOpen') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartOpenTasksDetail = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartOpenTasksDetail, vm.chartTaksDetailOpen.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartOpenTasksDetail');
                        })
                    })
                } else if (args.containerId == 'chartTaksDetailClosed') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartClosedTasksDetail = vm.annexaBoxChartComponent.updateTimelineLeftRightDependant(
                                vm.chartClosedTasksDetail, vm.chartTaksDetailClosed.options, args.currentIndex, 0, 1
                            );

                            vm.annexaBoxChartComponent.refreshChart('chartClosedTasksDetail');
                        })
                    })
                }
            });
        }]
    })
    .component('annexaChartRegInputDetail',{
        templateUrl: './components/common/annexa-chart/annexa-chart-reg-input-detail.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', 'AnnexaChartFactory', '$scope', '$filter', function(AnnexaBIFactory, AnnexaChartFactory, $scope, $filter) {
            var vm = this;

            var currentIndex = 0;

            var initizlizeCharts = function(values) {
                vm.chartRegInputDetail = AnnexaChartFactory.get2TimelineStackedChart('regInputDetail', 'value1', 'value2', [$filter('translate')('global.literals.telematics'), $filter('translate')('global.literals.noTelematics')], values, 'global.literals.telematicRegEntries');

                currentIndex = vm.chartRegInputDetail.baseOption.timeline.currentIndex;

                vm.chartRegInputDetailTotal = {
                    greenValue: $linq(vm.chartRegInputDetail.options[currentIndex].series[0].data).sum("x => x.value"),
                    greyValue: $linq(vm.chartRegInputDetail.options[currentIndex].series[1].data).sum("x => x.value"),
                    greenLabel: 'global.literals.telematics',
                    greyLabel: 'global.literals.noTelematics',
                    greyColor: '#00a3cc'
                }
            }

            initizlizeCharts(AnnexaBIFactory.BIValues);

            $scope.$on('annexaChartTimelineChanged', function(event, args) {
                if(args.containerId == 'chartRegInputDetail') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartRegInputDetailTotal = {
                                greenValue: $linq(vm.chartRegInputDetail.options[currentIndex].series[0].data).sum(),
                                greyValue: $linq(vm.chartRegInputDetail.options[currentIndex].series[1].data).sum(),
                                greenLabel: 'global.literals.telematics',
                                greyLabel: 'global.literals.noTelematics',
                                greyColor: '#00a3cc'
                            }

                            vm.annexaBoxChartComponent.refreshChart('chartRegInputDetailTotal');
                        })
                    })
                }
            });
        }]
    })
    .component('annexaChartRegOutputDetail',{
        templateUrl: './components/common/annexa-chart/annexa-chart-reg-output-detail.html',
        require: {
            annexaBoxChartComponent: '^^annexaBoxChart'
        },
        controller:['AnnexaBIFactory', 'AnnexaChartFactory', '$scope', '$filter', function(AnnexaBIFactory, AnnexaChartFactory, $scope, $filter) {
            var vm = this;

            var currentIndex = 0;

            var initizlizeCharts = function(values) {
                vm.chartRegOutputDetail = AnnexaChartFactory.get2TimelineStackedChart('regOutputDetail', 'value1', 'value2', [$filter('translate')('global.literals.telematics'), $filter('translate')('global.literals.noTelematics')], values, 'global.literals.telematicRegEntries');

                currentIndex = vm.chartRegOutputDetail.baseOption.timeline.currentIndex;

                vm.chartRegOutputDetailTotal = {
                    greenValue: $linq(vm.chartRegOutputDetail.options[currentIndex].series[0].data).sum("x => x.value"),
                    greyValue: $linq(vm.chartRegOutputDetail.options[currentIndex].series[1].data).sum("x => x.value"),
                    greenLabel: 'global.literals.telematics',
                    greyLabel: 'global.literals.noTelematics',
                    rightColor: '#00a3cc'
                }
            }

            initizlizeCharts(AnnexaBIFactory.BIValues);

            $scope.$on('annexaChartTimelineChanged', function(event, args) {
                if(args.containerId == 'chartRegOutputDetail') {
                    setTimeout(function () {
                        $scope.$apply(function () {
                            currentIndex = args.currentIndex;

                            vm.chartRegOutputDetailTotal = {
                                greenValue: $linq(vm.chartRegOutputDetail.options[currentIndex].series[0].data).sum(),
                                greyValue: $linq(vm.chartRegOutputDetail.options[currentIndex].series[1].data).sum(),
                                greenLabel: 'global.literals.telematics',
                                greyLabel: 'global.literals.noTelematics',
                                rightColor: '#00a3cc'
                            }

                            vm.annexaBoxChartComponent.refreshChart('chartRegOutputDetailTotal');
                        })
                    })
                }
            });
        }]
    })