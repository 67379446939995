angular
    .module('annexaApp')
    .component('annexaAddressTypePermission', {
        templateUrl: './components/common/annexa-address-type-permission/annexa-address-type-permission.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addPermission = function () {
            	var modal = angular.copy(CommonAdminModals.adddressTypePermissionNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
                AnnexaFormlyFactory.showModal('modalNewPermission', modal, vm.savePermission, false);
            }
            
            vm.savePermission = function(permission){
            	if(permission && permission.annexaFormly && permission.annexaFormly.model){
		        	var addressTypePermission = {
	        			permissionType:permission.annexaFormly.model.permissionType.id,
	        			city:((permission.annexaFormly.model.city)?permission.annexaFormly.model.city.city:undefined), 
	        			profiles:[]
		        	}
		        	if(vm.addressTypeId){
		        		addressTypePermission.addressType = {id:vm.addressTypeId};
            		}
            		if(permission.annexaFormly.model.profiles){
		        		_.forEach(permission.annexaFormly.model.profiles, function(profile){
		        			addressTypePermission.profiles.push({profile:{id:profile.id, language1:profile.language1, language2:profile.language2, language3:profile.language3}});
		        		});
		        	}
		        	if(!vm.isNew){
	        			RestService.insert('./api/territory/address_type_permission', addressTypePermission).then(function(data) {
	        				if(data){
	                            vm.permissions.push(JSOG.decode(data));	        					
		        			}
			        		if(GlobalDataFactory.addressTypes){
		                    	GlobalDataFactory.addressTypes.length = 0;
		                    }else{
		                    	GlobalDataFactory.addressTypes = [];
		                    }
		                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
		                    	CacheFactory.get('globalDataCache').remove('AddressType');
		                    }
		                    GlobalDataFactory.loadGlobalData();
		                    permission.close();
                        }).catch(function() {
                        	organMember.alerts.push("Error");
                        });
		        	}else{
		        		vm.permissions.push(addressTypePermission);
		        		permission.close();
		        	}
            	}
            }

            vm.editPermission = function (permission, index) {
            	var modal = angular.copy(CommonAdminModals.adddressTypePermissionNew);
            	var permissionToUpdate = angular.copy(permission);
            	if(permissionToUpdate.permissionType){
            		permissionToUpdate.permissionType = $linq(apiAdmin.addressTypePermissionType).firstOrDefault(undefined,"x => x.id == '"+permissionToUpdate.permissionType+"'");
            		if(permissionToUpdate.permissionType && permissionToUpdate.permissionType.id){
            			permissionToUpdate.permissionType = angular.copy(permissionToUpdate.permissionType);
            		}
            	}
            	if(permissionToUpdate.city && permissionToUpdate.city.id){
            		permissionToUpdate.city = permissionToUpdate.city.name;
            	}
            	if(permissionToUpdate.profiles){
            		var profiles = angular.copy(permissionToUpdate.profiles);
            		permissionToUpdate.profiles.length = 0;
            		_.forEach(profiles, function(profile){
            			permissionToUpdate.profiles.push(profile.profile);
            		});
            	}
            	modal.extra = {
	            	permission:permission,
	            	index:index
            	}
            	modal.annexaFormly.model = permissionToUpdate;
            	modal.annexaFormly.options = { watchAllExpressions: true };
            	AnnexaFormlyFactory.showModal('modalNewPermission', modal, vm.updatePermission, false);
            }
            
            vm.updatePermission = function(permission){
            	if(permission.extra.permission){
	            	if(permission && permission.annexaFormly && permission.annexaFormly.model){
			        	if(!vm.isNew){
			        		var permissionAux = angular.copy(permission.extra.permission);
			        		permissionAux.permissionType = permission.annexaFormly.model.permissionType.id;
		            		if(permission.annexaFormly.model.city  && permission.annexaFormly.model.city.city){
		            			permissionAux.city = permission.annexaFormly.model.city.city; 
		            		}else if(!permission.annexaFormly.model.city){
		            			permissionAux.city = undefined;
		            		}
		            		if(vm.addressTypeId){
		            			permissionAux.addressType = {id:vm.addressTypeId};
		            		}
		            		permissionAux.profiles = []
				        	if(permission.annexaFormly.model.profiles){
				        		_.forEach(permission.annexaFormly.model.profiles, function(profile){
				        			permissionAux.profiles.push({profile:{id:profile.id, language1:profile.language1, language2:profile.language2, language3:profile.language3}});
				        		});
				        	}
		            		RestService.update('./api/territory/address_type_permission/' + permissionAux.id, permissionAux).then(function (data) {
                    			if(data){
                    				var decoded = JSOG.decode(data);
                    				permission.extra.permission.permissionType = decoded.permissionType;
        		            		permission.extra.permission.city = decoded.city; 
        		            		permission.extra.permission.profiles = decoded.profiles;
                    			}
		            			if(GlobalDataFactory.addressTypes){
                    				GlobalDataFactory.addressTypes.length = 0;
                    			}else{
                    				GlobalDataFactory.addressTypes = [];
                    			}
                    			if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
                    				CacheFactory.get('globalDataCache').remove('AddressType');
                    			}
                    			GlobalDataFactory.loadGlobalData();
                    			permission.close();
                            }).catch(function () {
                            	permission.alerts.push("Error");
                            });
			        	}else{
			        		permission.extra.permission.permissionType = permission.annexaFormly.model.permissionType.id;
		            		if(permission.annexaFormly.model.city  && permission.annexaFormly.model.city.city){
		            			permission.extra.permission.city = permission.annexaFormly.model.city.city; 
		            		}else if(!permission.annexaFormly.model.city){
		            			permission.extra.permission.city = undefined;
		            		}
		            		permission.extra.permission.profiles = []
				        	if(permission.annexaFormly.model.profiles){
				        		_.forEach(permission.annexaFormly.model.profiles, function(profile){
				        			permission.extra.permission.profiles.push({profile:{id:profile.id, language1:profile.language1, language2:profile.language2, language3:profile.language3}});
				        		});
				        	}
			        		permission.close();
			        	}
	            	}
            	}
            }
            
            vm.deletePermission = function (permission, index) {
               	DialogsFactory.confirm('global.territory.list.confirmRemovePermissionTitle', 'global.territory.list.confirmRemovePermissionBody').then(function (dataAux) {
           			if(permission){
		        		var permissionIndex = -1;
		        		if(permission.id){
		        			permissionIndex = $linq(vm.permissions).indexOf("x => x.id == "+permission.id);
		        		}else{
		        			permissionIndex = index;
		        		}
		        		if(permissionIndex > -1){
				        	if(!vm.isNew){
				        		if(permission && permission.id && ( permissionIndex || permissionIndex == 0) && vm.permissions && vm.permissions.length >= permissionIndex){
		                    		RestService.delete('./api/territory/address_type_permission/' + permission.id).then(function (data) {
		                    			vm.permissions.splice(permissionIndex, 1);
	                    				if(GlobalDataFactory.addressTypes){
					                    	GlobalDataFactory.addressTypes.length = 0;
					                    }else{
					                    	GlobalDataFactory.addressTypes = [];
					                    }
					                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('AddressType')){
					                    	CacheFactory.get('globalDataCache').remove('AddressType');
					                    }
					                    GlobalDataFactory.loadGlobalData();
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		                        }
				        	}else{
				        		vm.permissions.splice(permissionIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            vm.printProfiles = function(permission){
            	var profiles = '';
            	if(permission && permission.profiles){
            		_.forEach(permission.profiles, function(profile){
            			if(profile.profile && profile.profile[vm.languageColumn]){
            				profiles = profiles+((profiles == '')?'':', ')+profile.profile[vm.languageColumn];
            			}
            		});
            	}
            	return profiles;
            }
            
            vm.printType = function(permission){
            	var type = '';
            	if(permission && permission.permissionType){
            		if(permission.permissionType == 'CREATE'){
            			type = $filter('translate')('global.literals.create');
            		}else{
            			type = $filter('translate')('global.literals.edit');
            		}
            	}
            	return type;
            }

            this.$onInit = function(){
            }
            
        }],
        bindings: {
        	permissions: '=',
            isNew: '=',
            addressTypeId:'=?'
        }
    })