/**
 * Created by osirvent on 24/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaAlerts', {
        templateUrl: './components/common/annexa-alerts/annexa-alerts.html',
        controller: [function() {
            var vm = this;

            if(!vm.alerts) {
                vm.alerts = [];
            }

            vm.closeAlert = function(index) {
                vm.alerts.splice(index, 1);
            };
        }],
        bindings: {
            alerts: '=?'
        }
    })