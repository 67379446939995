angular
    .module('annexaApp')
    .component('annexaFormEditable',{
        templateUrl: './components/common/annexa-form-editable/annexa-form-editable.html',
        controller:['Language', '$rootScope', function(Language, $rootScope) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.showIf = function (field, model) {
                if(field.showIf != undefined) {
                    return field.showIf(model);
                }

                return true;
            }

            vm.showEset = function(field) {
                if(field.removeOnEset) {
                    if($rootScope.esetMode) {
                        return false;
                    }
                }

                return true;
            }

            vm.getPermissions = function (field) {
                if(field.editPermissions) {
                    return field.editPermissions
                }

                return vm.permissions;
            }
        }],
        bindings: {
            id: '@',
            submit: '=',
            fieldsDefinition: '=',
            model: '=',
            permissions: '=',
            inDivRow: '='
        }
    })