angular
    .module('annexaApp')
    .component('annexaProfileUsers',{
        templateUrl: './components/common/annexa-profile-users/annexa-profile-users.html',
        controller:[function() {
            var vm = this;

            vm.getUserName = function (user) {
                return ' ' + user.name + ' ' + user.surename1 + (user.surename2 ? ' ' + user.surename2 : '');
            }

            vm.getUserPhone = function (user) {
                var phone = ' ';

                phone += user.phone ? user.phone : '';
                phone += user.phone && user.cellphone ? ' / ' : '';
                phone += user.cellphone ? user.cellphone : '';

                return phone;
            }
        }],
        bindings: {
            profile: '='
        }
    })