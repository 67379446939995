angular
    .module('annexaApp')
    .component('annexaFormModalObject',{
        templateUrl: './components/common/annexa-form-modal/annexa-form-modal-object.html',
        controller:['$rootScope', '$scope', function ($rootScope, $scope) {
            var vm = this;

            vm.getIconClass = function () {
                if(!vm.modalObject.icon.startsWith('&#x')) {
                    return vm.modalObject.icon;
                } else {
                    return 'material-icons md-36';
                }
            }

            vm.getIcon = function () {
                if(!vm.modalObject.icon.startsWith('&#x')) {
                    return '';
                } else {
                    return vm.modalObject.icon;
                }
            }

            vm.closeAlert = function (index) {
                vm.modalObject.alerts.splice(index, 1);
            }

            vm.submitForm = function () {
                var a = 0;
            }

            vm.save = function () {
                if(vm.id == 'modalNewRegisterEntry'){
                    vm.modalObject.submitModal();
                }else{
                    $rootScope.$broadcast('submitForm', { id: vm.id });
                }
            }

            $scope.$on('closeAnnexaFormModal', function (event, args) {
                if(args.id && args.id == vm.id) {
                    vm.modalObject.close();
                }
            })

            this.$onInit = function () {
                if(!vm.modalObject.submitLabel) {
                    vm.modalObject.submitLabel = 'global.literals.save';
                }

                if(!vm.modalObject.closeLabel) {
                    vm.modalObject.closeLabel = 'global.literals.cancel';

                }
            }
        }],
        bindings:{
            id: '@',
            modalObject: '='
        }
    })