angular
    .module('annexaApp')
    .component('annexaReportQueryComponent', {
        templateUrl: './components/common/annexa-report-query-component/annexa-report-query-component.html',
        controller: ['$http', '$rootScope', function($http, $rootScope) {
            var vm = this;

            vm.inputTextType = 'text';

            this.$onInit = function () {
                if(!vm.showFunctionParam){
                    vm.showFunction = function(){
                        return true;
                    };
                }else{
                    vm.showFunction = vm.showFunctionParam;
                }
            };

            this.$onChanges = function (changes) {
                if(changes.options) {
                    if(typeof vm.options === 'string') {
                        var url = vm.options;
                        vm.options = [];

                        $http({
                            url: url,
                            method: 'GET'
                        }).then(function(data) {
                            var decodedData = JSOG.decode(data.data);
                            vm.options = angular.fromJson(decodedData);
                        }).catch(function (error) {
                            vm.options = [];
                        });
                    }
                }
            };
        }],
        bindings: {
            key: '@',
            label: '@',
            model: '=?',
            options: '<',
            optionOnSelect: '=',
            showFunctionParam: '='
        }
    });