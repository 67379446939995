angular
    .module('annexaApp')
    .component('annexaBoxUsers', {
        templateUrl: './components/common/annexa-box-users/annexa-box-users.html',
        controller: ['$rootScope', 'GlobalDataFactory','Language', '$filter', '$scope', 'HelperService', 'AnnexaFormlyFactory', function ($rootScope, GlobalDataFactory, Language, $filter, $scope, HelperService, AnnexaFormlyFactory) {
            var vm = this;

            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASUsers') {
                	var addedObjects = [];
                    var id = undefined;
                    _.forEach(args.selected, function (item) {
                		if(id){
                    		id =id+','+item.id;
                    	}else{
                    		id = item.id;
                    	}
                    	addedObjects.push(item);
                    });
                    
                    if(addedObjects && addedObjects.length > 0){
                    	vm.search.searchedValue = { id: id, object: addedObjects };
                        vm.search.completeAdd();
                    }
                    args.modal.close();
                }
            });

            this.$onInit = function() {
                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.users.list.boxUsersDefaultToptitle'; 
                }

                if(vm.search) {
                	vm.search.addedSelect = 'x => x.user.id';
                    vm.search.origin = 'common';
                    vm.search.type = 'User';
                    vm.search.customFilter = '{ "identifier": "@VAL@", "name": "@VAL@"}';
                    vm.search.processSearch = function (object) {
                    	return object.name + ' ' + object.surename1 + (object.surename2 ? ' ' + object.surename2 : '') + " (" + object.identifier + ")";
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                            var newContent = [];
                            if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                        		_.forEach(vm.search.searchedValue.object, function(obj){
                        			newContent.push({user: JSOG.decode(obj)});
                        		});
                        	}else{
                    			newContent.push({user: JSOG.decode(vm.search.searchedValue.object)} );
                        	}
                            vm.search.searchedValue = { id: -1, value: '' };
                    		if(!vm.search.saveSearch) {
                    			_.forEach(newContent, function(obj){
                    				vm.content.push(obj);
                            	});
                            }else{
                                if(vm.search.saveSearch) {
                                    vm.search.saveSearch(newContent);
                                }
                            }
                        }
                    }

					vm.search.advancedSearch = function() {
                        var getAddedFunction = function() {
                        	var added = $linq(vm.content).select("x => x.user.id").toArray();
                        	return added;
                        }
                        
                        var renderName = function(data, type, full, meta) {
                            var content = '';

                            if(full) {
                            	content = full.name + ' ' + full.surename1 + (full.surename2 ? ' ' + full.surename2 : '');
                            }

                            return content;
                        }
                        
		                var advancedSearchModal = {
                            title: 'global.literals.advanced_search_user',
                            size: 'modal-lg',
                            filter: [
                            	{ id: 'identifier', type: 'text', order: 0, label: 'global.literals.user', userChoosen: false },
                            	{ id: 'name', type: 'text', order: 1, label: 'global.commonAdmin.datatable.name', userChoosen: false },
                            	{ id: 'acronym', type: 'text', order: 2, label: 'global.literals.acronym', userChoosen: false },
                            	{ id: 'email', type: 'text', order: 3, label: 'global.profile.email', userChoosen: false },
                            	{ id: 'phone', type: 'text', order: 4, label: 'global.profile.phone', userChoosen: true },
                            	{ id: 'extension', type: 'text', order: 5, label: 'global.literals.extension', userChoosen: true },
                            	{ id: 'cellPhone', type: 'text', order: 6, label: 'global.literals.cellPhone', userChoosen: true }
                            ],
                            tableDefinition: {
                                id: 'tableAdvancedSearchUsers',
                                origin: 'common',
                                objectType: 'User',
                                sortName: 'identifier',
                                sort: [[1, 'asc']],
                                columns: [
                                	{ id: 'identifier', title: $filter('translate')('global.literals.user'), sortable: true },
                                	{ id: 'name', title: $filter('translate')('global.commonAdmin.datatable.name'), render: renderName, sortable: true},
                                	{ id: 'acronym', title: $filter('translate')('global.literals.acronym'), sortable: true },
                                	{ id: 'email', title: $filter('translate')('global.profile.email'), sortable: true }
                                ],
                                noLoading: true,
                                callAuxToAdd: { added: getAddedFunction(),  isAdvanced:true, active:1}
                            },
                            multiple: true
                        }
                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASUsers', advancedSearchModal);
                    }
                }
            }
            
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@'
        }
    })
    .component('annexaBoxUsersRender', {
        templateUrl: './components/common/annexa-box-users/annexa-box-users-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['DialogsFactory', '$rootScope', 'HelperService','Language', function(DialogsFactory, $rootScope, HelperService,Language) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteUserBox')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxUsersDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })