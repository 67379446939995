angular
    .module('annexaApp')
    .component('annexaAddressStreetNumbers', {
        templateUrl: './components/common/annexa-address-street-numbers/annexa-address-street-numbers.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addStreetNumber = function () {
            	var modal = angular.copy(CommonAdminModals.streetNumberNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = { watchAllExpressions: true };
                AnnexaFormlyFactory.showModal('modalNewStreetNumber', modal, vm.saveStreetNumber, false);
            }
            
            vm.saveStreetNumber = function(streetNumber){
            	if(streetNumber && streetNumber.annexaFormly && streetNumber.annexaFormly.model){
            		var model = {
            			initialNumber:streetNumber.annexaFormly.model.initialNumber,
            			finalNumber:streetNumber.annexaFormly.model.finalNumber,
            			type:((streetNumber.annexaFormly.model.type && streetNumber.annexaFormly.model.type.id)?streetNumber.annexaFormly.model.type.id:undefined)
            		}
		        	if(vm.streetId){
		        		model.street = {id:vm.streetId};
            		}
		        	if(!vm.isNew){
	        			RestService.insert('./api/territory/street_street_numbers', model).then(function(data) {
	        				if(data){
	                            vm.numbers.push(JSOG.decode(data));	        					
		        			}
	        				streetNumber.close();
                        }).catch(function(error) {
                        	if(error && error.data && error.data.message == "Exist number"){
                        		streetNumber.alerts.push({ msg: $filter('translate')('global.errors.errorStreetNumber')});
                        	}else{
                        		streetNumber.alerts.push({ msg: "Error"});
                        	}
                        });
		        	}else{
		        		vm.numbers.push(model);
		        		streetNumber.close();
		        	}
            	}
            }

            vm.editStreetNumber = function (streetNumber, index) {
            	var modal = angular.copy(CommonAdminModals.streetNumberNew);
            	var streetNumberUpdate = angular.copy(streetNumber);
            	if(streetNumberUpdate.type){
            		streetNumberUpdate.type = {id:streetNumberUpdate.type};
            	}
            	modal.extra = {
            		streetNumber:streetNumber,
	            	index:index
            	}
            	modal.annexaFormly.model = streetNumberUpdate;
            	modal.annexaFormly.options = { watchAllExpressions: true };
            	AnnexaFormlyFactory.showModal('modalStreetNumberUpdate', modal, vm.updateStreetNumber, false);
            }
            
            vm.updateStreetNumber = function(streetNumber){
            	if(streetNumber.extra.streetNumber){
	            	if(streetNumber && streetNumber.annexaFormly && streetNumber.annexaFormly.model){
			        	if(!vm.isNew){
			        		var streetNumberAux = angular.copy(streetNumber.extra.streetNumber);
			        		streetNumberAux.initialNumber = streetNumber.annexaFormly.model.initialNumber; 
			        		streetNumberAux.finalNumber = streetNumber.annexaFormly.model.finalNumber;  
			        		streetNumberAux.type = ((streetNumber.annexaFormly.model.type && streetNumber.annexaFormly.model.type.id)?streetNumber.annexaFormly.model.type.id:undefined);  
		            		if(vm.streetId){
		            			streetNumberAux.street = {id:vm.streetId};
		            		}
		            		RestService.update('./api/territory/street_street_numbers/' + streetNumberAux.id, streetNumberAux).then(function (data) {
		            			if(data){
                    				var decoded = JSOG.decode(data);
       			        			streetNumber.extra.streetNumber.initialNumber = decoded.initialNumber; 
       			        			streetNumber.extra.streetNumber.finalNumber = decoded.finalNumber;  
       			        			streetNumber.extra.streetNumber.type = decoded.type;  
                    				streetNumber.extra.streetNumber.street = decoded.street; 
                    			}
                    			streetNumber.close();
                            }).catch(function (error) {
                            	if(error && error.data && error.data.message == "Exist number"){
                            		streetNumber.alerts.push({ msg: $filter('translate')('global.errors.errorStreetNumber')});
                            	}else{
                            		streetNumber.alerts.push({ msg: "Error"});
                            	}
                            });
			        	}else{
			        		if(streetNumber.annexaFormly.model.initialNumber){
			        			streetNumber.extra.streetNumber.initialNumber = streetNumber.annexaFormly.model.initialNumber; 
				        	}
			        		if(streetNumber.annexaFormly.model.finalNumber){
			        			streetNumber.extra.streetNumber.finalNumber = streetNumber.annexaFormly.model.finalNumber;  
		            		}
			        		if(streetNumber.annexaFormly.model.type && streetNumber.annexaFormly.model.type.id){
			        			streetNumber.extra.streetNumber.type = streetNumber.annexaFormly.model.type.id;  
		            		}
		            		streetNumber.close();
			        	}
	            	}
            	}
            }
            
            vm.deleteStreetNumber = function (streetNumber, index) {
               	DialogsFactory.confirm('global.territory.list.confirmRemoveStreetNumberTitle', 'global.territory.list.confirmRemoveStreetNumberBody').then(function (dataAux) {
           			if(streetNumber){
		        		var streetNumberIndex = -1;
		        		if(streetNumber.id){
		        			streetNumberIndex = $linq(vm.numbers).indexOf("x => x.id == "+streetNumber.id);
		        		}else{
		        			streetNumberIndex = index;
		        		}
		        		if(streetNumberIndex > -1){
				        	if(!vm.isNew){
				        		if(streetNumber && streetNumber.id && ( streetNumberIndex || streetNumberIndex == 0) && vm.numbers && vm.numbers.length >= streetNumberIndex){
		                    		RestService.delete('./api/territory/street_street_numbers/' + streetNumber.id).then(function (data) {
		                    			vm.numbers.splice(streetNumberIndex, 1);
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		                        }
				        	}else{
				        		vm.numbers.splice(streetNumberIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
           
            vm.printType = function(number){
            	var type = '';
            	if(number && number.type){
            		if(number.type == 'ALL'){
            			type = $filter('translate')('global.literals.all');
            		}else if (number.type == 'EVEN'){
            			type = $filter('translate')('global.territory.list.even');
            		}else if(number.type == 'ODD'){
            			type = $filter('translate')('global.territory.list.odd'); 
            		}
            	}
            	return type;
            }
            
            this.$onInit = function(){
            }
            
        }],
        bindings: {
        	numbers: '=',
            isNew: '=',
            streetId:'=?'
        }
    })