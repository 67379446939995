angular
    .module('annexaApp')
    .component('annexaCircleIcon', {
        templateUrl: './components/common/annexa-circle-icon/annexa-circle-icon.html',
        controller:[function() {
            var vm = this;

            this.$onInit = function () {
                var sizes = { xs: 16, sm: 24, md: 32, xl: 38, lg: 48};

                if(!vm.bgColor) {
                    vm.bgColor = 'primary';
                }

                if(!vm.textColor) {
                    vm.textColor = 'white'
                }

                if(!vm.iconSize) {
                    vm.iconSize = 'md'
                }

                if(!vm.title) {
                    vm.title = '';
                }

                vm.textColor = 'text-' + vm.textColor;

                if(!sizes[vm.iconSize]) {
                    vm.iconSize = 'md'
                }

                vm.iconSize = 'w-' + sizes[vm.iconSize];
                vm.textSize = "text-" + vm.iconSize;
            }
        }],
        bindings: {
            bgColor: '@?',
            textColor: '@?',
            iconSize: '@?',
            title: '@?',
            content: '@'
        }
    });