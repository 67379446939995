angular
    .module('annexaApp')
    .component('annexaLegend',{
        templateUrl: './components/common/annexa-legend/annexa-legend.html',
        controller:[function () {
            var vm = this;
        }],
        bindings: {
            legend: '='
        }
    })