angular
    .module('annexaApp')
    .component('annexaSelectResponsible', {
        templateUrl: './components/common/annexa-select-responsible/annexa-select-responsible.html',
        controller: ['$rootScope', 'Language', function( $rootScope, Language) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            
            vm.selected = 0;
            vm.changeSelected = function(item) {
            	vm.proposal.profile = item;
            	vm.proposal.selectedResponsible = undefined;
            }
            
            this.$onInit = function() {
            	if(!vm.profiles) {
                    vm.profiles = [];
                }
                if(!vm.proposal){
                	vm.proposal = {};
                }
                
                if (vm.profileId) {
                	vm.selected = vm.profileId;
            		vm.proposal.profile = $linq(vm.profiles).firstOrDefault(undefined, "x => x.id ==" + vm.profileId);
            	}
            	
            	if (vm.responsibleId) {
            		
            		var selectedResponsible = $linq(vm.proposal.profile.userProfiles).firstOrDefault(undefined, "x => x.user.id ==" + vm.responsibleId);
            		if (selectedResponsible && selectedResponsible.user) {
            			vm.proposal.selectedResponsible = selectedResponsible.user;
            		}
            	}
            }

        }],
        bindings: {
            profiles: '=',
            label: '=',
            proposal: '=',
            profileId: '=',
            responsibleId: '='
        }
    })