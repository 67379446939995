angular
    .module('annexaApp')
    .component('annexaQueryParameterDefinition', {
        templateUrl: './components/common/annexa-query-parameter-definition/annexa-query-parameter-definition.html',
        controller:['RestService', 'CommonAdminService', 'Language', '$q', '$filter', 'CommonAdminModals', 'AnnexaFormlyFactory', 'ErrorFactory', '$rootScope', 'HelperService', function (RestService, CommonAdminService, Language, $q, $filter, CommonAdminModals, AnnexaFormlyFactory, ErrorFactory, $rootScope, HelperService) {
            var vm = this;

            vm.editedIndex = undefined;

            vm.addedQueryParameter = undefined;

            vm.otherQueries = [];

            vm.languageColumn = Language.getActiveColumn();

            var saveNewQueryParameter = function() {
                var self = this;
                var data = this.annexaFormly.model.modal_body;

                data.pressButton = true;

                if(this.annexaFormly.form.$valid) {
                    var newQueryParameter = {
                        language1: data.language1,
                        language2: data.language2,
                        language3: data.language3,
                        queryParameterType: data.queryParameterType,
                        alias: data.alias
                    };

                    CommonAdminService.insertQueryParameter(newQueryParameter)
                        .then(function(data) {
                            addQP(JSOG.decode(data));

                            self.close();
                        }).catch(function(error) {
                            self.alerts.push({ msg: ErrorFactory.getErrorMessage('queryParameters', 'new', error) });
                    });
                }

                data.pressButton = undefined;

            };

            var createNewQueryParameter = function() {
                var modal = CommonAdminModals.queryParameters;

                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { language1:'', language2:'', language3:'', queryParameterType:'' };
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false};
                modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.otherQueries = [];

                AnnexaFormlyFactory.showModal('modalQueryParameter' + new Date().getTime(), modal, saveNewQueryParameter, false);
            };


            var addQP = function(newQueryParameter) {
                var toAddQueryParameter = {
                    queryParameter: newQueryParameter,
                    viewOrder: vm.queryParameters.length,
                    value: ''
                };

                if(vm.updateOnChange && vm.parentProperty && vm.parentId) {
                    toAddQueryParameter[vm.parentProperty] = { id: vm.parentId };

                    vm.update(toAddQueryParameter);
                }

                vm.queryParameters.push(toAddQueryParameter);

                return toAddQueryParameter;
            };

            var saveEditQueryParameter = function() {
                if(vm.editedIndex != undefined) {
                    var self = this;
                    var data = this.annexaFormly.model.modal_body;

                    data.pressButton = true;

                    if (this.annexaFormly.form.$valid) {
                        var newQueryParameter = angular.copy(vm.queryParameters[vm.editedIndex].queryParameter);
                        newQueryParameter.language1 = data.language1;
                        newQueryParameter.language2 = data.language2;
                        newQueryParameter.language3 = data.language3;
                        newQueryParameter.queryParameterType = data.queryParameterType;
                        newQueryParameter.alias = data.alias;

                        CommonAdminService.updateQueryParameter(newQueryParameter)
                            .then(function(data) {
                                vm.queryParameters[vm.editedIndex].queryParameter = JSOG.decode(data);
                                vm.editedIndex = undefined;
                                self.close();
                            }).catch(function (error) {
                                self.alerts.push({ msg: ErrorFactory.getErrorMessage('queryParameters', 'update', error) });
                        });
                    }

                    data.pressButton = undefined;

                }
            };

            vm.editQueryParameter = function(index) {
                vm.editedIndex = index;
                vm.otherQueries = [];

                var editQueryParameter = angular.copy(vm.queryParameters[index].queryParameter);
                var queriesFromQueryQueryParameter = angular.copy(vm.queryParameters[index].query);
                var modal = CommonAdminModals.queryParameters;
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = editQueryParameter;
                modal.annexaFormly.options = { formState: { readOnly: false } };
                modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.otherQueries = [];

                if (editQueryParameter && editQueryParameter.relatedQueries) {
                    _.forEach(editQueryParameter.relatedQueries, function(item) {
                        if (queriesFromQueryQueryParameter && queriesFromQueryQueryParameter.id != item.id) {
                            vm.otherQueries.push(item);
                        } else if (queriesFromQueryQueryParameter == undefined){
                            vm.otherQueries.push(item);
                        }
                    });
                }

                if (vm.otherQueries && vm.otherQueries.length > 0) {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.queryParameterUsedOtherQueries'), type: 'warning' });
                    modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.otherQueries = vm.otherQueries;
                }

                AnnexaFormlyFactory.showModal(
                    'modalQueryParameter' + new Date().getTime(),
                    modal,
                    saveEditQueryParameter,
                    false
                );
            };

            vm.addQueryParameter = function(newQueryParameter) {
                if(newQueryParameter.id != -1) {
                    addQP(newQueryParameter);

                    var indexAllqueryParameters = $linq(vm.allQueryParameters).indexOf("x => x.id == " + newQueryParameter.id);

                    if(indexAllqueryParameters != -1) {
                        vm.allQueryParameters.splice(indexAllqueryParameters, 1);
                    }
                } else {
                    createNewQueryParameter();
                }

                vm.addedQueryParameter = undefined;
            };

            vm.removeQueryParameter = function(index) {
                var queryParameterToRemove = {};
                if(index >= 0 && vm.queryParameters){
                    queryParameterToRemove = vm.queryParameters[index];
                }
                if(vm.updateOnChange) {
                    RestService.delete('./api/query/query_query_parameter/' + vm.queryParameters[index].id)
                        .then(function (data) {
                            vm.queryParameters.splice(index, 1);
                            vm.addRemovedQueryParameterToAllQueryParameters(queryParameterToRemove);
                            if(vm.reloadFunction){
                                vm.reloadFunction(vm.parentId);
                            }
                        }).catch(function (error) {
                            console.log(error);
                    });
                } else {
                    vm.queryParameters.splice(index, 1);
                    vm.addRemovedQueryParameterToAllQueryParameters(queryParameterToRemove);
                }
            };

            vm.addRemovedQueryParameterToAllQueryParameters = function(queryParameterToRemove){
                if(queryParameterToRemove && queryParameterToRemove.queryParameter){
                    vm.allQueryParameters.push(queryParameterToRemove.queryParameter);
                    vm.allQueryParameters = $linq(vm.allQueryParameters).orderBy("x => x." + vm.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                }
            };


            vm.sort = function(item, partFrom, partTo, indexFrom, indexTo){
                if(partTo){
                    _.forEach(partTo, function(value, key){
                        value.viewOrder = key + 1;

                        vm.update(value);
                    });
                }
            };

            vm.updateQueryParameter =  function(qpId, value){
                if(qpId) {
                    var qPs = $linq(vm.queryParameters).where("x => x.queryParameter && x.queryParameter.id == " + qpId).toArray();
                    if(qPs && qPs.length > 0){
                        if(value || value == ''){
                            qPs[0].value = value;
                        }
                        vm.update(qPs[0]);
                    }
                }
            };

            vm.update = function(queryParameter) {
                if(vm.updateOnChange) {
                    if(queryParameter.id) {
                        RestService.update('./api/query/query_query_parameter/' + queryParameter.id, queryParameter)
                            .then(function (data) {
                                if(vm.reloadFunction){
                                    vm.reloadFunction(vm.parentId);
                                }
                            }).catch(function (error) {
                                console.error(error);
                        });
                    } else {
                        RestService.insert('./api/query/query_query_parameter', queryParameter)
                            .then(function (data) {
                                vm.queryParameters[vm.queryParameters.length - 1].id = data.id;
                                vm.queryParameters[vm.queryParameters.length - 1].queryParameter.id = data.queryParameter.id;
                                if(vm.reloadFunction){
                                    vm.reloadFunction(vm.parentId);
                                }
                            }).catch(function (error) {
                                console.error(error);
                        });
                    }
                }
            };

            this.$onInit = function() {
                if(!vm.queryParameters) {
                    vm.queryParameters = [];
                }

                vm.allQueryParameters = [
                    { id: '-1', value: '', language1: $filter('translate')('global.literals.new'), language2: $filter('translate')('global.literals.new'), language3: $filter('translate')('global.literals.new')}
                ];

                RestService.findAll('QueryParameter')
                    .then(function(data) {
                        var responseQP = JSOG.decode(data.data);
                        var parametresOK = [];
                        _.forEach($linq(responseQP).orderBy("x => x." + vm.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray(), function(item) {
                        	parametresOK.push(item);
                        });

                        if(parametresOK && vm.queryParameters){
                        	parametresOK = $linq(parametresOK).except(vm.queryParameters, function(x,y){
                                if(x.queryParameter && x.queryParameter.id == y.id){
                                    return true;
                                }
                                return false;
                            }).toArray();
                        	vm.allQueryParameters.length = 0;
                        	vm.allQueryParameters.push({ id: '-1', value: '', language1: $filter('translate')('global.literals.new'), language2: $filter('translate')('global.literals.new'), language3: $filter('translate')('global.literals.new')});
                        	_.forEach(parametresOK, function(param){
                        		vm.allQueryParameters.push(param);
                        	})
                        }
                    }).catch(function(error) {
                        console.error(error);
                });

            };
        }],
        bindings: {
            queryParameters: '=',
            updateOnChange: '=',
            parentId: '=',
            parentProperty: '@',
            reloadFunction: '=?'
        }
    })
    .component('annexaQueryAccordion', {
        templateUrl: './components/common/annexa-query-parameter-definition/annexa-query-accordion.html',
        controller: ['Language', function (Language) {
            var vm = this;
            vm.isQueryOpen = false;
            vm.languageColumn = Language.getActiveColumn();

            vm.updateOpenQueryStatus = function () {
                vm.isQueryOpen = vm.otherQueries.some(function (item) {
                    return item.isQueryOpen;
                });
            };
        }],
        bindings: {
            otherQueries: '='
        }
    })
;