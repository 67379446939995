/**
 * Created by osirvent on 08/05/2017.
 */
angular
    .module('annexaApp')
    .component('tabset', {
        templateUrl: './components/common/tabset/tabset.html',
        controller: [function () {
            var vm = this;
            vm.classTabset = "row"

            if(vm.type == 'pills') {
                vm.classTabset += ' primary-pills';
            }
        }],
        bindings: {
            id: '@',
            type: '@',
            tabs: '='
        }
    })