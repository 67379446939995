angular
    .module('annexaApp')
    .component('annexaCustomFieldComponent', {
        templateUrl: './components/common/annexa-custom-field-component/annexa-custom-field-component.html',
        controller: ['$http', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaEntityFactory', function($http, $rootScope, globalModals, AnnexaFormlyFactory, AnnexaEntityFactory) {
            var vm = this;

            //region Datepicker

            vm.datepicker = {};

            vm.datepicker.opened = false;

            vm.datepicker.open = function ($event) {
                vm.datepicker.opened = !vm.datepicker.opened;
            };

            vm.datepickerOptions = {
                format: 'dd/MM/yyyy',
                initDate: new Date(),
                showWeeks: false,
                startingDay: 1
            }

            //endregion

            //region Number

            vm.step = 0;
            vm.actualModel = '';

            if(vm.frontendType == 'INPUT') {
                vm.inputTextType = 'text';

                if(!vm.minLength) {
                    vm.minLength = 0;
                }

                if(!vm.maxLength) {
                    vm.maxLength = 9999;
                }

                if(!vm.decimalPositions) {
                    vm.decimalPositions = 0;
                }

                if (vm.backendType == 'INTEGER') {
                    vm.inputTextType = 'number';
                    vm.step = 1;
                } else if (vm.backendType == 'DECIMAL') {
                    vm.inputTextType = 'number';
                    vm.step = '0.' + Array(vm.decimalPositions).join('0') + '1';
                }
            }

            if(vm.frontendType == 'TEXTAREA' || vm.frontendType == 'JSON') {
                if(!vm.minLength) {
                    vm.minLength = 0;
                }

                if(!vm.maxLength) { 
                    vm.maxLength = 9999;
                }

            }

            //endregion

            //region Select

            vm.clear = function($event,model$select) {
                $event.stopPropagation();
                model = undefined;
                if($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
            }

            //endregion
            
            //region Address
            
            vm.openModalAddress = function(model, key) {
                var modal = angular.copy(globalModals.editAddressCF);
                
                var defaultCountry = $rootScope.app.configuration.default_country;
                if(defaultCountry && defaultCountry.value){
                    defaultCountry = defaultCountry.value;
                }else{
                    defaultCountry = '';
                }
                
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                
                if(model != "") {
                	modal.annexaFormly.model.modal_body = JSON.parse(model);
                }
                
                if(!modal.annexaFormly.model.modal_body.country && defaultCountry){
                    modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                }
                
                modal.annexaFormly.model.modal_body.key = key;
                modal.annexaFormly.model.modal_body.hasStates = true;
                modal.annexaFormly.model.modal_body.hasCities = true;
                modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                
                modal.extra = this;
                
                
                var submitFunction = function() { 
                    var self = this;
                    
                    var address = {};
                    var addressAux = this.annexaFormly.model.modal_body;

                    address.country = addressAux.country;
                    if(addressAux.hasStates) {
                        if (addressAux.validState && addressAux.validState.id) {
                            address.validState = addressAux.validState;
                        }
                    }else{
                        address.stateAddress = addressAux.stateAddress;
                    }
                    if(addressAux.hasCities){
                        if(addressAux.validCity && addressAux.validCity.id) {
                            address.validCity = addressAux.validCity;
                        }
                    }else{
                        address.city = addressAux.city;
                    }
                    if(addressAux.hasStreetTypes && addressAux.streetType) {
                        address.streetType = addressAux.streetType;
                    }

                    address.street = addressAux.street;
                    address.isValidNumber = false;
                    address.numberAddres = addressAux.numberAddres;
                    address.floor = addressAux.floor;
                    address.block = addressAux.block;
                    address.door = addressAux.door;
                    address.stair = addressAux.stair;
                    address.postalCode = addressAux.postalCode;
                    
                    vm.optionOnSelect(addressAux.key, JSON.stringify(address));
                    vm.modelValue = AnnexaEntityFactory.postalAddressRender(address)
                    
                    self.close();
                }
                
                AnnexaFormlyFactory.showModal("modalNewAddress", modal, submitFunction, false);
            };
            
            //endregion

            this.$onInit = function () {
            	if(!vm.isRequired){
            		vm.isRequired = false;
            	}
                if(!vm.showFunctionParam){
                    vm.showFunction = function(){
                        return true;
                    }
                }else{
                    vm.showFunction = vm.showFunctionParam;
                }
                if(typeof vm.options === 'string') {
                    var url = vm.options;
                    vm.options = [];

                    $http({
                        url: url,
                        method: 'GET'
                    }).then(function(data) {
                        var decodedData = JSOG.decode(data.data);
                        vm.options = angular.fromJson(decodedData);
                    }).catch(function (error) {
                        vm.options = [];
                    });
                }
                if(vm.backendType  == 'DATETIME' && vm.model){
                    var dateParts = vm.model.split("/");
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
                    vm.actualModel = dateObject;
                }else  if (vm.frontendType == 'INPUT' && (vm.backendType == 'INTEGER')) {
                    vm.actualModel = parseInt(vm.model);
                } else if (vm.frontendType == 'INPUT' && vm.backendType == 'DECIMAL') {
                    if(vm.model){
                        var valueLiteral_parts = (vm.model).split('.');
                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
                            if(valueLiteral_parts[1]){
                                if(vm.decimalPositions){
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(vm.decimalPositions));
                                }else{
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                }
                            }else{
                                if(vm.decimalPositions){
                                    vm.actualModel =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(vm.decimalPositions));
                                }else {
                                    vm.actualModel =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                }
                            }
                        }
                    }
                }  else if(vm.frontendType == 'INPUT' && vm.backendType == 'ADDRESS') {
                	if(vm.model && vm.model != ""){
                		vm.modelValue = AnnexaEntityFactory.postalAddressRender(JSON.parse(vm.model));
                	}
                } else if(vm.frontendType == 'RADIO' || vm.frontendType == 'SELECT'){
                    if(vm.backendType == 'INTEGER' && vm.model){
                    	vm.actualModel = parseInt(vm.model);
                    }else{
                    	vm.actualModel = vm.model;
                    }
                }else if(vm.frontendType == 'CHECKBOX'){
                    vm.actualModel = [];
                    if(vm.options && vm.optionValueProp){
                        var modelsSelected = [];
                        if(vm.model){
                            modelsSelected = vm.model.split(',');
                        }
                        _.forEach(vm.options, function(option){
                            if(_.contains(modelsSelected, ""+option[vm.optionValueProp])){
                                vm.actualModel[option[vm.optionValueProp]] = true;
                            }else{
                                vm.actualModel[option[vm.optionValueProp]] = false;
                            }
                        });
                    }
                }else if(vm.frontendType == 'MULTIPLESELECT'){
                    vm.actualModel = [];
                    if(vm.options && vm.optionValueProp){
                        var modelsSelected = [];
                        if(vm.model){
                            modelsSelected = vm.model.split(',');
                        }
                        _.forEach(modelsSelected, function(option){
                            if(vm.backendType == 'INTEGER' && option){
                            	vm.actualModel.push(parseInt(option));
                            }else{
                            	vm.actualModel.push(option);
                            }
                        });
                    }
                }
            }

            this.$onChanges = function (changes) {
                if(changes.options) {
                    if(typeof vm.options === 'string') {
                        var url = vm.options;
                        vm.options = [];

                        $http({
                            url: url,
                            method: 'GET'
                        }).then(function(data) {
                            var decodedData = JSOG.decode(data.data);
                            vm.options = angular.fromJson(decodedData);
                            if(vm.options && vm.options.length > 0){
                            	var defaultOption = $linq(vm.options).firstOrDefault(undefined, "x=>x.defaultValue == true")
                            	if(defaultOption){
                            		vm.actualModel = defaultOption.id;
                            	}
                            }
                        }).catch(function (error) {
                            vm.options = [];
                        });
                    }
                }
            }

            var previousModel;

            this.$doCheck = function() {
                if(!angular.equals(previousModel, this.model)) {
                    previousModel = this.model;

                    $rootScope.$broadcast('annexaCustomFieldComponentModelChanged', { key: this.key, model: this.model, plugin: this.plugin });
                }
            };

            vm.optionOnSelectAux = function(key, type){
                if(type){
                    if(type == 'DATETIME'){
                        if(vm.actualModel) {
                            var date = new Date(vm.actualModel);

                            var dd = date.getDate();
                            var mm = date.getMonth() + 1;

                            var yyyy = date.getFullYear();

                            if (dd < 10) {
                                dd = '0' + dd;
                            }

                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                            vm.model = dd + '/' + mm + '/' + yyyy;
                        }else{
                            vm.model = '';
                        }
                        if(vm.optionOnSelect){
                        	vm.optionOnSelect(key, vm.model);
                        }

                    }else if(type == 'number' || type == 'RADIO' || type == 'SELECT'){
                        if(vm.actualModel){
                            vm.model = vm.actualModel;
                        }else{
                            vm.model = '';
                        }
                        if(vm.optionOnSelect){
                        	vm.optionOnSelect(key, vm.model);
                        }
                    }else if(type == 'CHECKBOX') {
                        vm.model = '';
                        _.forEach(vm.options, function (option, index) {
                            if (vm.actualModel[option[vm.optionValueProp]]) {
                                if (!vm.model) {
                                    vm.model += option[vm.optionValueProp];
                                } else {
                                    vm.model += "," + option[vm.optionValueProp];
                                }
                            }
                        });
                        if(vm.optionOnSelect){
                        	vm.optionOnSelect(key, vm.model);
                        }
                    } else if(type == 'MULTIPLESELECT'){
                        vm.model = '';
                        if(vm.actualModel) {
                            _.forEach(vm.actualModel, function (option, index) {
                                if (!vm.model) {
                                    vm.model += option;
                                } else {
                                    vm.model += "," + option;
                                }
                            });
                        }
                        if(vm.optionOnSelect){
                        	vm.optionOnSelect(key, vm.model);
                        }
                    }
                }
            }
        }],
        bindings: {
            key: '@',
            frontendType: '@',
            backendType: '@',
            label: '@',
            model: '=?',
            minLength: '=',
            maxLength: '=',
            decimalPositions: '=',
            isRequired: '=?', 
            cfDisabled: '=',
            optionLabelProp: '@',
            optionValueProp: '@',
            options: '<',
            optionsInline: '=',
            optionOnSelect: '=',
            fc: '=',
            plugin: '<?',
            showFunctionParam: '='
        }
    })
	.directive('validateJson', function() {
		return {
		    require: 'ngModel',
		    restrict: '',
		    link: function(scope, elm, attrs, ctrl) {
		        ctrl.$validators.validateJson = function(modelValue) {
		        	if (modelValue) {
			        	try {
		            		return JSON.parse(modelValue);
		                } catch (error) {
		        			return false;
		        		}
		        	}
		        };
		    }
		};
	});