angular
    .module('annexaApp')
    .factory('alertspctFactory', ['$q', '$http', function($q, $http) {
        var factory = {};

        factory.getPending = function() {
            var deferred = $q.defer();

            $http({
                url: './api/alerts/pending',
                method: 'GET',
                headers: { noLoading: true }
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function (error) {
                console.error(error);
                deferred.reject(error);
            });

            return deferred.promise;
        }

        return factory;
    }])
    .factory('signpctFactory', ['$q', '$http', function($q, $http) {
        var factory = {};

        factory.getPending = function() {
            var deferred = $q.defer();

            $http({
                url: './api/signprocessactions/pending',
                method: 'GET',
                headers: { noLoading: true }
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function (error) {
                console.error(error);
                deferred.reject(error);
            });

            return deferred.promise;
        }

        return factory;
    }])
    .factory('taskspctFactory', ['$q', '$http', function($q, $http) {
        var factory = {};

        factory.getPending = function() {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/pending',
                method: 'GET',
                headers: { noLoading: true }
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function (error) {
                console.error(error);
                deferred.reject(error);
            });

            return deferred.promise;
        }

        return factory;
    }])
    .factory('AnnexaPendingCounterFactory',['$rootScope', '$interval', 'GlobalDataFactory', '$injector', function($rootScope, $interval, GlobalDataFactory, $injector) {
        var factory = {};

        factory.counters = {};

        var stop;

        factory.updateModuleCounters = function() {
            factory.counter = {};

            var modules = angular.copy(GlobalDataFactory.modules);
            modules.unshift({ serviceName: 'alerts' });

            _.forEach(modules, function (item) {
                var factoryName = item.serviceName + 'pctFactory';

                if($injector.has(factoryName)) {
                    factory.counters[item.serviceName] = 0;

                    var ctFactory = $injector.get(factoryName);
                    if(ctFactory.getPending) {
                        ctFactory.getPending()
                            .then(function(data) {
                                factory.counters[item.serviceName] = data;

                                $rootScope.$broadcast('annexaPendingCounterUpdate',{ counter: item.serviceName, value: data });
                            }).catch(function(error) {
                                console.error('Error on counter: ', error);
                        })
                    }
                }

            })
        }

        var fight = function() {
            if(angular.isDefined(stop)) {
                return;
            }
            var timeToCall = 5; //Minuts
            if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.pending_call_time && $rootScope.app.configuration.pending_call_time.time && $rootScope.app.configuration.pending_call_time.time > 0){
            	timeToCall = $rootScope.app.configuration.pending_call_time.time;
            }
            timeToCall = timeToCall * 60000;
            stop = $interval(function() {
                if($rootScope.LoggedUser) {
                    factory.updateModuleCounters();
                }
            }, timeToCall);
        }

        var stopFight = function() {
            if(angular.isDefined(stop)) {
                $interval.cancel(stop);
                stop = undefined;
            }
        }

        factory.runCounters = function() {
            stopFight();

            fight();
        }

        return factory;
    }])
    .component('annexaPendingCounter', {
        templateUrl: './components/common/annexa-pending-counter/annexa-pending-counter.html',
        controller: ['$scope', 'AnnexaPendingCounterFactory', function ($scope, AnnexaPendingCounterFactory) {
            var vm = this;

            vm.number = AnnexaPendingCounterFactory.counters[vm.counter] ? AnnexaPendingCounterFactory.counters[vm.counter] : 0;

            $scope.$on('annexaPendingCounterUpdate', function(event, args) {
                if(vm.counter == args.counter) {
                    vm.number = args.value;
                }
            })
        }],
        bindings: {
            counter: '@'
        }
    })